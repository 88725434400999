import { useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";

const NewsLetter = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.target);

    console.log(data.get('email'));
    const email = data.get('email')
    setLoading(true);
    API.post(URLS.NEWSLETTER.SUBSCRIPTION.create, {email}).then((resp) => {
      setLoading(false);
      toast.success(t("textThankForSubscribing"));
    }).catch((error) => {
      console.log(error?.response?.data)
      setLoading(false);
      toast.error(error?.response?.data?.email[0] ?? t("textNewsletterFailed"));
    })
  };

  return (
    <section className="section-box mt-50 mb-20">
      <div className="container">
        <div className="box-newsletter">
          <div className="row">
            <div className="col-xl-3 col-12 text-center d-none d-xl-block">
              <img
                src="/assets/imgs/template/newsletter-left.png"
                alt="joxBox"
              />
            </div>
            <div className="col-lg-12 col-xl-6 col-12">
              <h2
                className="text-md-newsletter text-center"
                dangerouslySetInnerHTML={{ __html: t("textNewsWill") }}
              ></h2>
              <div className="box-form-newsletter mt-40">
                <form className="form-newsletter" onSubmit={handleSubmit}>
                  <input
                    className="input-newsletter"
                    required
                    type="email"
                    name="email"
                    placeholder={t("textEnterEmail")}
                  />
                  <button
                    className="btn btn-default font-heading icon-send-letter d-flex"
                    type="submit"
                    disabled={loading}
                  >
                    <span className="my-auto">
                      {t("textSubscribe")}{" "}
                    </span>
                    {loading && (
                      <div
                        className="spinner-border my-auto ml-10 spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}{" "}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-xl-3 col-12 text-center d-none d-xl-block">
              <img
                src="/assets/imgs/template/newsletter-right.png"
                alt="joxBox"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsLetter;
