import { useTranslation } from "react-i18next";
import AuthFooter from "../../components/footer";
import ApplicationCard from "./components/ApplicationCard";
import useSWR from "swr";
import { URLS } from "../../../../services/urls";
import Footer from "../../../../components/footer";

const MyApplications = (props) => {
  const { t } = useTranslation();
  const { data, isLoading } = useSWR(
    URLS.USERS.APPLICATIONS.myApplications("", 1)
  );

  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">{t("myApplications")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <a className="icon-home" href="#">
                  {t("menu_user")}
                </a>
              </li>
              <li>
                <span>{t("myApplications")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-30">
                <div className="box-padding">
                  {isLoading && (
                    <div className="d-flex">
                      <div
                        className="spinner-border m-auto spinner-border-md "
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <div className="row display-list">
                    {data?.results?.map((application) => (
                      <ApplicationCard
                        key={application.id}
                        application={application}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyApplications;
