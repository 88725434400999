import Footer from "../footer";
import { Outlet } from "react-router-dom";
import AuthHeader from "../header/AuthHeader";
import NavBar from "../navBar";
import PrivateRoute from "../../routes/components/PrivateRoute";

const UserDashboardLayout = () => {
  return (
    <PrivateRoute>

    <div className="user-dash">
      <AuthHeader />
      <main className="main dashboard">
        <NavBar />
        <Outlet />
      </main>
      {/* <Footer /> */}
    </div>
    </PrivateRoute>
  );
};

export default UserDashboardLayout;
