

export const fr = {
    passMustMatch: "Les mots de passe doivent correspondre",
      requis : "Requis",
      noPassProvided: "Aucun mot de passe fourni.",
      passToShort: "Le mot de passe est trop court - doit être de 8 caractères minimum.",
      passOnlyLatinLetters: "Le mot de passe ne peut contenir que des lettres latines.",
      startForFree: "Démarrez gratuitement aujourd'hui",
      acessAllFeatures: "Accès à toutes les fonctionnalités. Aucune carte de crédit requise.",
      firstName: "Prénom",
    lastName: "Nom de famille",
    email: "Email",
    password: "Mot de passe",
      confirmPassword: "Confirmer le mot de passe",
      acceptTerms: "Accepter nos conditions et notre politique",
      learnMore: "En savoir plus",
      submitNRegister: "Soumettre et s'inscrire",
      déjàHaveAccount: "Vous avez déjà un compte ?",
      signIn: "Connexion",
      RememberMe: "Se souvenir de moi",
      forgotPassword: "Mot de passe oublié",
      actionLogin: "Connexion",
      dontHaveAccount: "Vous n'avez pas de compte ?",
      signUp: "S'inscrire",
      phoneNumber: "Numéro de téléphone",
      emailValidationError: "Erreur de validation de l'e-mail ;",
      myProfiles: "Mes profils",
      menu_user: "Utilisateur",
      addNew: "Ajouter",
      updateYourCV: "Mettre à jour votre CV",
      uploadFile: "Télécharger le fichier",
      boutonSupprimer: "Supprimer",
      postTitle: "Titre de l'offre",
      minSalary : "Salaire minimum",
      maxSalary: "Salaire maximum",
      userBio: "Bio",
      btnContinuer: "Continuer",
      profileSkills: "Compétences du profil",
      btnUpdate: "Mettre à jour",
      titreÉducation: "Éducation",
      educationDe: "De",
      educationTo: "À",
      educationDegree: "Diplôme",
      educationÉcole: "École",
      educationGpa: "Note",
      educationfieldOfStudy: "Domaine d'études",
      educationCollège: "Collège",
      currentlyWorking: "Je travaille actuellement ici",
      currentlyStudying: "Étudie actuellement ici",
      currentlyShort: "Actuel",
      addTimeline: "Ajouter une chronologie",
      educationAjouté: "L'éducation a été ajoutée avec succès à votre profil",
      menuAccueil: "Accueil",
      myResume: "Mon CV",
      editTimeline: "Modifier la chronologie",
      educationModifié: "Éducation modifiée avec succès",
      deleteAreYouSure: 'Êtes-vous sûr ?',
      deleteIrreversibleAlert: "Vous ne pourrez pas revenir en arrière !",
      deleteActionButton: "Oui, supprimez-le !",
      suppressionSupprimé: "Supprimé !",
      deleteSuccessMessage: 'Votre opération de suppression a réussi.',
      deleteActionCancel: "Annuler",
      deleteProcess: "Suppression en cours",
      requestGlobalErrorMessage: "Quelque chose s'est mal passé",
      experienceSociété: "Société",
      textDescription: "Description",
      workExperience : "Travail & Expérience",
      experienceAdded: "Expérience ajoutée avec succès sur votre profil",
      experienceEdited: "Expérience modifiée avec succès",
      expérienceSecteur: "Secteur d'activité",
      markCvDefault: "Marquer le CV par défaut",
      deleteButtonText: "Supprimer",
      introEditedSuccessfuly: "Profil modifié avec succès",
      textDefault: "Par défaut",
      jobsOfTheDay : "Les métiers du jour",
      searchAndConnect: "Cherchez et décrochez l'emploi de vos rêves.",
      textSearch: "Rechercher",
      postAJob: "Publier une offre d'emploi",
      textAdmin: "Administrateur",
      postNewJob: "Publier un nouvel emploi",
      jobInformation: "Informations sur l'emploi",
      jobTitle: "Intitulé du poste",
      JobType: "Type",
      WorkplaceType: "Type de lieu de travail",
      workingTypePart: "Temps partiel",
      workingTypeFull: "Temps plein",
      workingTypeRemote: "A distance",
      workingTypeFreelancer: "Freelancer",
      jobExperienceYear: "Nombre d'années d'expérience",
      jobExperienceLevel: "Niveau d'expérience",
      jobExpEntry: "Débutant",
      jobExpInterm: "Intermédiaire",
      jobExpSenior: "Senior",
      jobExpert : "Expert",
      jobMinSalary: "Salaire minimum",
      jobMaxSalary: "Salaire max.",
      jobWorkPlaceOnsite: "Sur site",
      jobWorkPlaceRemote: "A distance",
      jobWorkPlaceHybrid: "Hybride",
      jobApplicationEnd: "Fin de la candidature",
      jobVisaSponsored: "Visa sponsorisé",
      jobDescription: "Description",
      jobResponsibilities: "Responsabilités du poste",
      jobSpecialNote: "Note de travail spéciale pour l'utilisateur",
      jobIndustrie: "Industrie",
      jobCompany : "Recrutement en entreprise",
      jobSkills : "Compétences",
      textJobs: "Offres",
      jobCountry: "Pays",
      jobCity: "Ville",
      jobProcess: "Processus de recrutement (séparés par des virgules)",
      jobIncludePertners: "Inclure les partenaires",
      textJobDetails: "Détails de l'offre",
      textJobApplications: "Demandes d'emploi",
      textCvTheque: "Cv Thèque",
      textProfileDetails: "Détails du profil",
      textDownloadCv: "Télécharger le CV",
      textProfileSteps: "Étapes du profil",
      textShortBio: "Courte biographie",
      textSkills: "Compétences",
      textAboutMe: "À propos de moi",
      textOverview: "Aperçu",
      textExpectedSalary: "Salaire attendu",
      textLinkedIn: "LinkedIn",
      textPhone: "Téléphone",
      textEmail: "E-mail",
      buttonSendMessage: "Envoyer le message",
      textMyProfile: "Mon Profil",
      textUpdateProfile: "Mettre à jour votre profil",
      textUploadAvatar: "Télécharger l'avatar",
      textFirstName: "Prénom",
      textLastName: "Nom de famille",
      textPhoneNumber: "Numéro de téléphone",
      textLanguage: "Langue",
      buttonSaveChange: "Enregistrer la modification",
     textContactInformation: "Coordonnées",
     textLngFrench: "Français",
     textLngEnglish: "Anglais",
     textSocialNetwork: "Réseau social",
     textTwitter: "Twitter",
     textWebsite: "Site Web",
     textLinkedin: "Linkedin",
     textTwitter: "Twitter",
     textPerMonth: "Mois",
     textEmploymentInfo: "Informations sur l'emploi",
     textIndustry: "Industrie",
     textJobLevel: "Niveau de l'offre",
     textSalary: "Salaire",
     textExperience: "Expérience",
     textYears: "Années",
     textJobType: "Type d'offre",
     textCreated: "Créé",
     textDeadline: "Date limite",
     textLocation: "Emplacement",
     textApplyNow: "Postuler maintenant",
     textShareJob: "Partager",
     textEmail: "E-mail",
     textSimilarJobs: "Emplois similaires",
     footerDescription: "Ovasourcing emploi est le leader du secteur du placement de personnel et du conseil RH au Cameroun et en Afrique centrale",
     footerTitleContactInfo: "Coordonnées",
     footerAddress: "Adresse",
     footerTitleLinks: "LIEN DU SITE",
     textAllJobs: "Toutes les offres",
     textContact: "Contact",
     footerTitleNewsletter: "Newsletter",
     footerTextNewslettet: "Inscrivez-vous à notre newsletter pour recevoir toutes nos offres en continu.",
     textJObsAvailable: "Emplois disponibles",
     textSearch: "Rechercher",
     textFindTheJOb: "Trouvez l'emploi qui vous convient",
     textJobBoard: `
     Le <span class="text-primary">conseiller d'emploi n°1 pour</span>
                 <br class="d-none d-lg-block" />
                 trouver l'emploi qui vous convient`,
     textYourKeyword: "Votre mot-clé...",
     textInscriptionCreateAccount: "S'inscrire et créer des profils",
     textInscriptionCreateAccountText: "Est-ce votre première visite sur notre site ? Si oui, nous vous invitons à créer un compte et à compléter votre profil pour terminer le processus de candidature.",
     textSearchJob: "Rechercher un emploi",
     textSearchJobText: "Entrez un intitulé de poste ou un mot-clé, Entrez un emplacement Cliquez sur le bouton Rechercher un emploi.",
     textHowToApply: "Comment postuler ?",
     textHowToApplyText: "Une fois que vous avez trouvé une offre qui vous plaît, vous avez deux choix : postuler via votre profil, ou via votre CV",
     textSeeSomeWords: "Voir quelques mots",
     textThousand: "Des milliers d'employés obtiennent leur emploi idéal",
     andFeedBack: "et faites-nous part de vos commentaires !",
     jobApplicationSuccess: "Candidature soumise avec succès.",
     textNewProfile: "Nouveau profil",
     myApplications: "Mes candidatures",
     textAppliedOn: "Postulé le",
     textLoginingOut: "Déconnexion...",
     textDashboard: "Tableau de bord",
     textApplications: "Candidatures",
     textMyProfile: "Mon profil",
     cvManage: "Gérer mon CV",
     textLogout: "Déconnexion",
     textAdvanceFilter: "Filtre avancé",
     textReset: "Réinitialiser",
     textDescription: "Description",
     tetSkills: "Compétences",
     textResponsabilities: "Responsabilités",
     textProfileEditedSuccess: "Profil modifié avec succès",
     textApplicationStatus: "Statut de la candidature",
     text_sent: "Envoyé",
     text_in_progress: "En cours",
     text_interview: "Entretien",
     text_test: "En test",
     text_hr: "RH",
     text_human_ressources: "Ressources humaines",
     text_rejected: "Refusé",
     text_validated: "Validé",
     text_waiting_user: "En attente de votre confirmation",
     text_accepted: "Accepté",
     text_canceled: "Annulé",
     textHome: "Accueil",
     textAvailableNow: "Disponibles maintenant",
     textGithub: "Github",
     textMadeBy: "Fait par",
     textUser: "Utilisateur",
     textFindJob: "Trouver un emploi",
     textYourAccount: "Votre compte",
     textLogin: "Connexion",
     textRegister: "S'inscrire",
     textSignIn: "Connexion",
     textNoJob: "Aucune offre d'emploi trouvée",
     textLoginError: "Impossible de se connecter avec les informations d'identification fournies.",
     textWelcomeBack: "Bienvenue !",
     textAtMost: "L'e-mail doit comporter au maximum 50 caractères",
     textValidEmail: "L'adresse e-mail doit être une adresse valide",
     textEmailNotFound: "E-mail introuvable",
     textResetPw: "Réinitialiser le mot de passe",
     textRecoverPwEmailSend: "L'e-mail de réinitialisation du mot de passe a été envoyé.",
     textConfirmError: "La confirmation de la réinitialisation du mot de passe a échoué",
     textPwMustMatch: "Vos mots de passe ne correspondent pas",
     textSetNewPassword: "Définir un nouveau mot de passe",
     textNoNotifs: "Aucune notification trouvée",
     textNotifications: "Notifications",
     textTitle: "Titre",
     textContent: "Contenu",
     textNotificationsDetails: "Détails de la notification",
     textNotifDetailsError: "Désolé, nous ne pouvons pas afficher cette notification, veuillez réessayer plus tard",
     textProfileEditedError: "Désolé, nous ne pouvons pas modifier le profil et une erreur s'est produite",
     textEmailVerified: "E-mail vérifié avec succès",
     textResendVerificationAction: "Renvoyer",
     textResendVerification: "Renvoyer le code de vérification",
     textCheckEmail: "Vérifier l'e-mail et réessayer",
     textKeyResend: "Le code de vérification a été renvoyé. Vérifiez votre boîte aux lettres !",
     emailRevalidationError: "Nous ne pouvons pas envoyer le code de revalidation, veuillez vérifier votre adresse e-mail et réessayer.",
     textEmailVerificationKeyNotFound: "Clé de vérification de l'e-mail introuvable. Essayez de vous connecter ou renvoyez le lien de vérification.",
     textNberPlaces: "Postes ouverts",
     textNberAppl: "Candidatures",
     textGender: "Sexe",
     textAge: "Âge",
     textChoose: "Choisissez une option",
     textMale: "Homme",
     textFemale: "Femme",
     textMale: "Homme",
     textNewsWill: `Les nouveautés seront toujours<br/> mises à jour régulièrement`,
     textSubscribe: "S'abonner",
     textEnterEmail: "Entrez votre email ici",
     textThankForSubscribe: "Merci de vous être abonné à notre newsletter.",
     textNewsletterFailed: "Votre abonnement a échoué.",
     textOurProcess: "Notre processus",
     textAppliedJobs: "Candidatures",
     textJobsCount: "Total d'offres",
     textJobFound: "Emplois trouvée",
     textProfiles: "Profils",
     textRecommendedJobs: "Offres recommandées",
     textTopRecruitment: "Top recrutement",
     textApplicationDetails: "Détails de la candidature",
     textHistory: "Historique",
     textApplicationInformations: "Informations sur la candidature",
     textAppliedOn: "Postulé le",
     textIsActive: "Est actif",
     textYes: "Oui",
     textNo: "Non",
     textStep: "Étape",
     textJob: "Offre",
     textPreviousStep: "Étape précédente",
     textApplicationNotFound: "Candidature introuvable",
     textActions: "Actions",
     textCancelApplication: "Annuler ma candidature",
     textAcceptJob: "Accepter l'offre",
     textYesCancel: "Oui, annuler",
     textApplicationCancelError: "Une erreur s'est produite lors de l'annulation de votre candidature",
     textApplicationCancelled: "Demande d'emploi annulée !",
     textApplicationAccepted: "Candidature acceptée",
     textApplicationAcceptationError: "L'acceptation de l'offre a échoué",
     textIntroduction: "Introduction",
     textCertifications: "Certifications",
     textLanguages: "Langues",
     textReferences: "Références",
     textAddEducation: "Ajouter une formation",
     textEditEducation: "Modifier l'éducation",
     textPrevious: "Précédent",
     textNext: "Suivant",
     textAddExperience: "Ajouter une expérience",
     textEditExperience: "Modifier l'expérience",
     textCertifName: "Nom de la certification",
     textDate: "Date",
     textCertifAdded: "Certification ajoutée avec succès",
     textCertifAddingError: "Erreur de création de la certification !",
     textAddCertification: "Ajouter une certification",
     textEditCertification: "Modifier la certification",
     textJoinedFile: "Fichiers joints",
     textLangName: "Nom de la langue",
     textLevel: "Niveau",
     textAddLanguage: "Ajouter une langue",
     textEditLanguage: "Modifier la langue",
     textLanguageAddingError: "Erreur d'ajout de langue !",
     languageEdited: "Langue modifiée avec succès",
     textLanguageAdded: "Langue ajoutée avec succès",
     textName: "Nom",
     textAddReference: "Ajouter une référence",
     textEditReference: "Modifier la référence",
     textReferenceAddingError: "Erreur d'ajout de référence !",
     referenceEdited: "Référence modifiée avec succès",
     textReferenceAdded: "Référence ajoutée avec succès",
     textViewResumes: "Afficher mes CV",
     textApplication: "Candidature d'emploi",
     textStartUrCareer: "Commencez votre carrière aujourd'hui",
     textChooseProfile: "Veuillez choisir votre profil et l'envoyer à l'employeur.",
     textFalse: "Faux",
     textTrue: "Vrai",
     textAdditionalInformations: "Informations supplémentaires",
     textFormFieldRequired: "Ce champ est obligatoire",
     textDateObtained: "Date d'obtention",
     textSubmit: "Soumettre",
     textBeginner: "Débutant",
     textIntermédiaire: "Intermédiaire",
     textAdvanced: "Avancé",
     textPopCat : "Catégorie populaire",
     textPopCatText : "Recherchez et connectez-vous plus rapidement avec les bons candidats.",
     textByLocation : "Emplois par emplacement",
     textByLocationText : " Trouvez vos emplois préférés et bénéficiez de vos avantages"
}