import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { URLS } from "../../../services/urls";
import _ from "lodash";
import { useState } from "react";
import { getQuantity } from "../../../components/utils/functions";

const SearchFilter = ({ handleFiler, locations, searchResults, count }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let [showLocation, setShowLocation] = useState();
  let [location, setLocation] = useState();

  const newSearchParams = new URLSearchParams();
  const { t } = useTranslation();
  const { data: categories, isLoading: loadingCategories } = useSWR(
    URLS.CONFIGS.PROFILE.SECTOR.list("")
  );
  // const { data, isLoading } = useSWR(URLS.JOBS.list(""));

  const handleChangeIndustry = (id) => {
    newSearchParams.set("industry", id);
    newSearchParams.set("location", searchParams.get("location") ?? "");
    newSearchParams.set("search", searchParams.get("search") ?? "");
    setSearchParams(newSearchParams);
  };
  const handleChangeLocation = (name) => {
    newSearchParams.set("location", name);
    newSearchParams.set("industry", searchParams.get("industry") ?? "");
    newSearchParams.set("search", searchParams.get("search") ?? "");
    setSearchParams(newSearchParams);
  };

  return (
    <div className="search-filters sidebar-shadow none-shadow mb-30">
      <div className="sidebar-filters">
        <div className="filter-block head-border mb-30">
          <h5>
            {t("textAdvanceFilter")}{" "}
            {/* <a className="link-reset" href="#">
              {t("textReset")}
            </a> */}
          </h5>
        </div>
        <div className="filter-block mb-30 form-find">
          <div
            className="form-group select-style select-style-icon"
            onClick={() => setShowLocation(!showLocation)}
          >
            <input
              className="form-control form-icons location-pointer"
              placeholder="Yaounde"
              value={searchParams.get("location")}
              onChange={handleChangeLocation}
              disabled
            />
            <i className="fi-rr-marker"></i>
          </div>
          {showLocation && (
            <div class="dropdown" id="dropdown" style={{marginTop: 0}}>
              {locations?.map((cat) => (
                <a
                  href="#"
                  className={location?.id === cat.id ? "active": searchParams.get("location") === cat.name ? "active" : ""}
                  key={cat?.id}
                  onClick={(event) => {
                    event.preventDefault();
                    handleChangeLocation(cat.name);
                    setShowLocation(false);
                    setLocation(cat);
                  }}
                >
                  <i class="bx bx-plus-circle"></i>
                  {cat?.name}
                </a>
              ))}
            </div>
          )}
        </div>
        <div className="filter-block mb-20">
          <h5 className="medium-heading mb-15">{t("textIndustry")}</h5>
          <div className="form-group">
            <ul className="list-checkbox">
              <li>
                <label
                  className="cb-container"
                  onClick={() => handleChangeIndustry("")}
                >
                  <input
                    type="checkbox"
                    checked={!searchParams.get("industry")}
                  />
                  <span className="text-small">All</span>
                  <span className="checkmark"></span>
                </label>
                <span className="number-item">{count}</span>
              </li>
              {!loadingCategories &&
                _.orderBy(
                  categories?.results,
                  [
                    "jobs",
                    function (o) {
                      return o.length;
                    },
                  ],
                  ["desc"]
                )
                  ?.slice(0, 7)
                  ?.map((category, index) => {
                    return category?.jobs?.length > 0 ? (
                      <li key={category?.id}>
                        <label
                          className="cb-container"
                          onClick={() => handleChangeIndustry(category.id)}
                        >
                          <input
                            type="checkbox"
                            checked={
                              searchParams.get("industry") === category.id
                            }
                          />
                          <span className="text-small">{category?.name}</span>
                          <span className="checkmark"></span>
                        </label>
                        <span className="number-item">

                          {
                            getQuantity(category, searchResults, category?.jobs?.length, searchParams.get("location") || searchParams.get("industry") || searchParams.get("search") )
                          }
                        </span>
                      </li>
                    ) : null;
                  })}
            </ul>
          </div>
        </div>
        {/* <div className="filter-block">
          <div>
            <div className="d-flex">
              <button onClick={() => handleFiler()} className="d-flex btn btn-default">
                {t("textSearch")}
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default SearchFilter;
