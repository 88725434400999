export const URLS = {
    // BASE_URL: "http://127.0.0.1:8000/api/",
    BASE_URL: "https://test.ovasourcing.ova-consulting.fr/api/",
    CLIENT_ID: "Bkzs9w6F7S8EbJCSOuLFXkh0L2IlbQ6a2mD5Xao2",
    AUTHS: {
      // LOGIN: "auth/mlogin/",
      LOGIN: "auth/login/",
      REFRESH: "auth/token/refresh/",
      LOGOUT: "auth/logout/",
      google: "auth/google/",
      REGISTRATION: "accounts/registration/",
      MANAGER_REGISTRATION: "accounts/staff/registration/",
      VERIFY_EMAIL: (key) => `accounts/accounts/confirm-email/${key}/`,
      VERIFY_ACCOUNT: (id) => `/users/verify/${id}/`,
      resetPassword: "accounts/password-reset/",
      resetPasswordAdmin: "accounts/password/admin-reset/",
      me: "users/me/",
      PASSWORD: {
        reset: "auth/password/reset/",
        confirm: "auth/password/reset/confirm/",
      },
      VERIFY: {
        email: "/accounts/email/verification/",
        resend: "/user/accounts/resend-email/",
      }
    },
    DASHBOARD: {
      list: "dashboard/user/"
    },
    CONFIGS: {
      PROFILE: {
        SKILLS: {
          create: "detail/profile/skills/",
          list: (search) => `detail/profile/skills/?search=${search}`,
        },
        COLLEGE: {
          create: "detail/profile/college/",
          list: (search) => `detail/profile/college/?search=${search}`,
        },
        COMPANY: {
          create: "detail/profile/company/",
          list: (search) => `configs/profile/company/?search=${search}`,
        },
        SECTOR: {
          create: "detail/profile/industry/",
          list: (search) => `detail/profile/industry/?search=${search}`,
        },
        COUNTRIES: {
          CITIES: {
            list: (search) => `detail/profile/countries/cities/?search=${search}`,
            cmr: (search) => `detail/profile/villes/?search=${search}&limit=500`,
          },
          list: (search) => `detail/profile/industry/?search=${search}`,
        },
        DIPLOMA: {
          create: "detail/profile/diploma/",
          list: (search) => `detail/profile/diploma/?search=${search}`,
        }
      }
    },
    USERS: {
      APPLICATIONS: {
        myApplications: (search, page) => `users/my_applications/?search=${search}&page=${page}`,
      },
      PROFILE: {
        my: "users/my_profiles/",
        create: "users/profile/",
        list: (search, page) => `users/profile/?search=${search}&page=${page}`,
        myProfiles: (search, page) => `users/my_profiles/?search=${search}&page=${page}`,
        update: (id) => `users/profile/${id}/`,
        details: (id) => `users/profile/${id}/`,
        EDUCATION: {
          create: "users/profile/education/",
          update: (id) => `users/profile/education/${id}/`,
          details: (id) => `users/profile/education/${id}/`,
        },
        EXPERIENCE: {
          create: "users/profile/experience/",
          update: (id) => `users/profile/experience/${id}/`,
          details: (id) => `users/profile/experience/${id}/`,
        },
        CERTIFICATION: {
          create: "users/profile/certification/",
          update: (id) => `users/profile/certification/${id}/`,
          details: (id) => `users/profile/certification/${id}/`,
        },
        LANGUAGES: {
          create: "users/profile/language/",
          update: (id) => `users/profile/language/${id}/`,
          details: (id) => `users/profile/language/${id}/`,
        },
        REFERENCE: {
          create: "users/profile/reference/",
          update: (id) => `users/profile/reference/${id}/`,
          details: (id) => `users/profile/reference/${id}/`,
        },
        setAsDefault: (id) => `users/profile/${id}/set_as_default/`,
      },
      update: (id) => `users/${id}/`,
    },
    JOBS: {
      list: (search) => `/jobs/?search=${search}/`,
      history: (search) => `/jobs/history/?search=${search}/`,
      details: (id) => `/jobs/${id}/`,
      historyDetails: (id) => `/jobs/history/${id}/`,
      apply: (id) => `/jobs/${id}/apply/`,
      byCategory: (categoryId) => `/jobs/?industry=${categoryId}/`,
      byIndustry: (name) => `/jobs/?industry_name=${name}/`,
      search: (page, search, category, location) => `/jobs/?page=${page ?? 1}&search=${search ?? ""}${category ? '&industry__id=' + category : ''}${location ? '&city__name=' + location : ''}`,
      APPLICATIONS: {
        details:  (id) => `jobs/applications/${id}/`,
        cancel: (id) => `jobs/applications/${id}/cancel/`,
        accept: (id) => `jobs/applications/${id}/accept/`,
      },
      ANSWERS: {
        create: `jobs/related_question_answer/`,
      }
    },
    NOTIFICATIONS: {
      messages: (search, page) => `notifications/messages/?search=${search}page=${page ?? 1}`,
      details: (id) => `notifications/messages/${id}/`
    },
    NEWSLETTER: {
      SUBSCRIPTION: {
        create: "newsletter/subscriptions/",
        list: "newsletter/subscriptions/",
      }
    }
}  