import { useTranslation } from "react-i18next";
import { URLS } from "../../../../services/urls";
import { useEffect, useRef, useState } from "react";
import { API } from "../../../../services/axios";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmDeletion } from "../../../../components/dletion";
import { Editor } from "@tinymce/tinymce-react";
import LanguageCard from "./components/LanguageCard";
import { ErrorMessage } from "@hookform/error-message";

const ManageLanguages = (props) => {
  const { resume, handleNext, handlePrev, handleRefetch } = props;
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [languages, setLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toEdit, setToEdit] = useState();
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const [selected, setSelected] = useState();
  const { action } = useParams();

  const editorRef = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const formRef = useRef(null);

  const handleChangeEducation = (certification) => {
    setToEdit(certification);
    reset({
      designation: certification?.designation,
      level: certification?.level,
    });
    setFile({ name: certification.file });
    editorRef?.current?.setContent(certification.description);
    formRef?.current?.scrollIntoView();
  };

  const handleDeleteEducation = (certification) => {
    const onSuccess = () => {
      setLanguages((prev) => {
        return prev.filter((item) => item.id != certification.id);
      });
    };
    confirmDeletion(
      URLS.USERS.PROFILE.LANGUAGES.update(certification.id),
      t,
      onSuccess
    );
  };

  const onSubmit = (data) => {
    console.log(selected);
    setLoading(true);
    let formData = new FormData();
    formData.append("designation", data.designation);
    formData.append("level", data.level);
    formData.append("profile", action);

    if (editorRef.current) {
      const description = editorRef.current.getContent();
      formData.append("description", description);
    }

    if (!toEdit) {
      API.post(URLS.USERS.PROFILE.LANGUAGES.create, formData)
        .then((resp) => {
          setLoading(false);
          reset();
          editorRef?.current?.setContent("<p></p>");
          toast.success(t("textLanguageAdded"));
          setLanguages((prev) => [{ ...resp.data }, ...prev]);
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ?? t("textLanguageAddingError")
          );
          setLoading(false);
        });
    } else {
      API.patch(URLS.USERS.PROFILE.LANGUAGES.update(toEdit.id), formData)
        .then((resp) => {
          setLoading(false);
          const { data } = resp;

          toast.success(t("languageEdited"));
          setLanguages((prev) => {
            const eds = prev?.map((ed) => {
              if (ed.id === toEdit.id) {
                ed.level = data.level;
                ed.description = data.description;
                ed.designation = data.designation;
              }
              return ed;
            });
            return eds;
          });
          setToEdit();
          reset();
          setSelected();
          handleRefetch();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (resume) {
      setLanguages(resume.languages);
    }
  }, [resume]);

  return (
    <div className="panel-white mb-30" id="education">
      <div className="box-padding">
        <h5 className="icon-edu">{t("textLanguages")}</h5>
        <div className="row mt-30">
          <div className="col-lg-9">
            <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("textLangName")}{" "}
                      <span className="text-primary">*</span>
                    </label>
                    <input
                      {...register("designation", {
                        required: t("textFormFieldRequired"),
                      })}
                      className="form-control"
                      type="text"
                      placeholder={t("textLangName")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="designation"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("textLevel")} <span className="text-primary">*</span>
                    </label>
                    <select
                      className="form-control"
                      {...register("level", { required: t("textFormFieldRequired") })}
                    >
                      <option value=""> {t("textChoose")} </option>
                      <option value="beginner"> {t("textBeginner")} </option>
                      <option value="intermediate"> {t("textIntermediate")} </option>
                      <option value="advanced"> {t("textAdvanced")} </option>
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="level"
                      render={({ message }) => (
                        <span className="text-primary">{message}</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-30">
                    <label className="font-sm color-text-mutted mb-10">
                      {t("textDescrption")}
                    </label>
                    <Editor
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue="<p></p>"
                      init={{
                        height: 300,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-10 d-flex">
                    <button className="btn btn-default btn-brand icon-tick ml-10">
                      {!toEdit ? t("textAddLanguage") : t("textEditLanguage")}
                      {loading && (
                        <span
                          className="spinner-border spinner-border-sm ml-10"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="box-timeline mt-50">
              {languages.map((language) => (
                <LanguageCard
                  key={language.id}
                  handleChange={handleChangeEducation}
                  handleDelete={handleDeleteEducation}
                  language={language}
                />
              ))}
            </div>
            <div className="col-lg-12 mt-3">
              <div className="form-group mt-10 d-flex">
                <button
                  onClick={handlePrev}
                  className="btn btn-secondary btn-brand line-height-24"
                >
                  {t("textPrevious")}
                </button>

                <button
                  onClick={handleNext}
                  className="btn btn-default btn-brand ml-auto"
                >
                  {t("textNext")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageLanguages;
