import { useTranslation } from "react-i18next";
import Clients from "../../../home/components/Clients";
import AuthFooter from "../../components/footer";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { URLS } from "../../../../services/urls";
import { useState } from "react";
import ResumeCard from "./components/ResumeCard";
import Footer from "../../../../components/footer";

const MyResumes = (props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const { data: profiles, isLoading: loadingProfiles } = useSWR(
    URLS.USERS.PROFILE.my
  );

  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">{t("myProfiles")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <a className="icon-home" href="#">
                  {t("menu_user")}
                </a>
              </li>
              <li>
                <span>{t("myProfiles")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-30 p-10">
                <div className="d-flex">
                  <Link
                    to="/my-resumes/manage/create"
                    className="btn btn-default ml-auto"
                  >
                    {t("addNew")}
                  </Link>
                </div>
              </div>
              <div className="panel-white mb-30">
                <div className="box-padding">
                  <div className="row display-list">
                    {
                      profiles?.results?.map((profile) => {

                        return(<ResumeCard key={profile.id} profile={profile} />)
                      })
                    }
                  </div>
                  {/* <div className="paginations">
                    <ul className="pager">
                      <li>
                        <a className="pager-prev" href="#"></a>
                      </li>
                      <li>
                        <a className="pager-number" href="#">
                          1
                        </a>
                      </li>
                      <li>
                        <a className="pager-number" href="#">
                          2
                        </a>
                      </li>
                      <li>
                        <a className="pager-number" href="#">
                          3
                        </a>
                      </li>
                      <li>
                        <a className="pager-number" href="#">
                          4
                        </a>
                      </li>
                      <li>
                        <a className="pager-number" href="#">
                          5
                        </a>
                      </li>
                      <li>
                        <a className="pager-number active" href="#">
                          6
                        </a>
                      </li>
                      <li>
                        <a className="pager-number" href="#">
                          7
                        </a>
                      </li>
                      <li>
                        <a className="pager-next" href="#"></a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-10">
        <div className="section-box">
          <div className="container">
            <div className="panel-white pt-30 pb-30 pl-15 pr-15">
              <Clients removePadding />
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default MyResumes;
