import { Link } from "react-router-dom";
import Clients from "../home/components/Clients";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../login/passwordInput.css';
import InputWithInfo from "../../components/info/InputWithInfo";


const schema = (t) => Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  phoneNumber: Yup.string().nullable(),
  email: Yup.string().email("").required(""),
  password: Yup.string()
  .required("noPassProvided") 
  .min(8, t("passToShort"))
  .matches(/[a-zA-Z]/, t("passOnlyLatinLetters")),
  confirmPassword: Yup
  .string()
  .oneOf([Yup.ref("password"), null], t("passMustMatch"))
  .required(t("required")),
}).required();

const Register = () => {
  const [loading, setLoading] = useState(false)
  const {t} = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema(t))
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (data) => {
    setLoading(true);
    const infos = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone_number: data.phoneNumber,
      password: data.password,
      password2: data.confirmPassword,
      is_superuser: false,
    };
    API.post(URLS.AUTHS.REGISTRATION, infos).then((resp) => {
      toast.success(resp.data.detail)
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
      toast.error(error?.response?.data?.detail);
    })
  };

  return (
    <div className="box-content">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-3">
                <div className="box-padding">
                  <div className="login-register">
                    <div className="row login-register-cover pb-3">
                      <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                        <div className="form-login-cover">
                          <div className="text-center">
                              {/* <p className="font-sm text-brand-2">Register </p>  */}
                              <h2 className="mt-10 mb-5 text-brand-1">{t("startForFree")}</h2>
                              <p className="font-sm text-muted mb-30">{t("acessAllFeatures")}</p>
                              {/* <button className="btn social-login hover-up mb-20"><img src="/assets/imgs/template/icons/icon-google.svg" alt="Ovasourcing"/><strong>Sign up with Google</strong></button>
                              <div className="divider-text-center"><span>Or continue with</span></div> */}
                            </div>
                          <form
                            className="login-register text-start mt-20"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group">
                              <label className="form-label" htmlFor="firstName">
                                {t("firstName")} *
                              </label>
                              <input
                                className="form-control"
                                id="firstName"
                                type="text"
                                required=""
                                name="firstName"
                                placeholder="Steven Job"
                                {...register("firstName")}
                              />
                              <span className="text-danger">{errors.firstName?.message}</span>
                            </div>
                            <div className="form-group">
                              <label className="form-label" htmlFor="lastName">
                                {t("lastName")} *
                              </label>
                              <input
                                className="form-control"
                                id="lastName"
                                type="text"
                                required=""
                                name="lastName"
                                placeholder="Job"
                                {...register("lastName")}
                              />
                              <span className="text-danger">{errors.lastName?.message}</span>

                            </div>
                            <div className="form-group">
                              <label className="form-label" htmlFor="phoneNumber">
                                {t("phoneNumber")} *
                              </label>
                              <InputWithInfo
                                className="form-control"
                                id="phoneNumber"
                                type="text"
                                required=""
                                name="phoneNumber"
                                placeholder=""
                                {...register("phoneNumber")}
                                infoText="Specify country indicator if you are not in Cameroon"
                              />
                              <span className="text-danger">{errors.phoneNumber?.message}</span>

                            </div>
                            <div className="form-group">
                              <label className="form-label" htmlFor="email">
                              {t("email")} *
                              </label>
                              <input
                                className="form-control"
                                id="input-2"
                                type="email"
                                required=""
                                name="emailaddress"
                                placeholder="stevenjob@gmail.com"
                                {...register("email")}
                              />
                              <span className="text-danger">{errors.email?.message}</span>
                            </div>
                            <div className="form-group">
                              <label className="form-label" htmlFor="password">
                              {t("password")} *
                              </label>
                              <input
                                className="form-control"
                                id="input-4"
                                type={showPassword ? 'text' : 'password'}
                                required=""
                                name="password"
                                placeholder="************"
                                {...register("password")}
                              />
                                <div className="eye-icon" onClick={togglePasswordVisibility}>
                                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash } />
                                </div>
                              <span className="text-danger">{errors.password?.message}</span>
                            </div>
                            <div className="form-group">
                              <label className="form-label" htmlFor="confirmPassword">
                              {t("confirmPassword")} *
                              </label>
                              <input
                                className="form-control"
                                id="input-5"
                                type="password"
                                required=""
                                name="confirmPassword"
                                placeholder="************"
                                {...register("confirmPassword")}
                              />
                              <span className="text-danger">{errors.confirmPassword?.message}</span>
                            </div>
                            <div className="login_footer form-group d-flex justify-content-between">
                              <label className="cb-container">
                                <input type="checkbox" {...register("agree")} />
                                <span className="text-small">
                                {t("agreeTerms")}
                                </span>
                                <span className="checkmark"></span>
                              </label>
                              <a className="text-muted" href="#">
                              {t("learnMore")}
                              </a>
                            </div>
                            <div className="form-group">
                              <button
                                className="btn btn-brand-1 hover-up w-100"
                                type="submit"
                                disabled={loading}
                              >
                                {t("submitNRegister")}
                                {loading && <div
                                  className="spinner-border ml-10 spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>}
                              </button>
                            </div>
                            <div className="text-muted text-center">
                              {t("alreadyHaveAccount")}
                              <Link to="/login">{t("signIn")}</Link>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Register;
