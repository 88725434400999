import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatLinkString } from "../utils/functions";

const JobCard = (props) => {
  const { job } = props;

  const {t} =useTranslation()

  return (
    <div className="card-grid-2 grid-bd-16 hover-up">
      <Link to={`/job/${job.id}/details/`}>
      <div className="card-grid-2-image">
        <span className="lbl-hot bg-green">
          <span>{job.type}</span>
        </span>
        <div className="image-box">
          <figure>
            <img src={job?.image ? formatLinkString(job?.image) : "/assets/imgs/page/homepage2/img1.png"} alt="Ovasourcing" />
          </figure>
        </div>
      </div>
      <div className="card-block-info">
        <h5>
          <>{job.title}</>
        </h5>
        <div className="mt-5">
          <span className="card-location mr-15">{job.country[0]?.name}, {job.city[0]?.name}</span>
          <span className="card-time">{moment(job?.created_at).fromNow()}</span>
        </div>
        <span className="card-time bg-none p-0">
        {t("textNberPlaces")}: {job.required}, {" "}
        {t("textNberAppl")}: {job.submitted},  {" "}
        {t("textDeadline")}: {moment(job?.application_end).fromNow()}
        </span>
        <div className="card-2-bottom mt-20">
          <div className="row">
            <div className="col-xl-12 col-md-12 mb-2">
              {
                job?.skills?.slice(0,2)?.map((skill) => (
                  <Link key={skill?.id} className="btn btn-tags-sm mr-5 text-paragraph-1" to="#">
                {skill?.name}
              </Link>
                )) 
              }
            </div>
            {/* <div className="col-xl-12 col-md-12 text-lg-end">
              <span className="card-text-price">XAF {job.salary_min}</span>
              <span className="text-muted">/{t('textPerMonth')}</span>
            </div> */}
          </div>
        </div>
        <p className="font-sm color-text-paragraph text-paragraph-2 mt-20" dangerouslySetInnerHTML={{__html: job.description}}>
        </p>
      </div>
      </Link>
    </div>
  );
};

export default JobCard;
