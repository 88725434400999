import { useTranslation } from "react-i18next";
import AuthFooter from "../../pages/userDashboard/components/footer";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <AuthFooter />
  );
};

export default Footer;
