export const en = {
    passMustMatch: "Passwords must match",
    required: "Required",
    noPassProvided: "No password provided.",
    passToShort: "Password is too short - should be 8 chars minimum.",
    passOnlyLatinLetters: "Password can only contain Latin letters.",
    startForFree: "Start for free Today",
    acessAllFeatures: "Access to all features. No credit card required.",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    agreeTerms: "Agree our terms and policy",
    learnMore: "Learn more",
    submitNRegister: "Submit & Register",
    alreadyHaveAccount: "Already have an account?",
    signIn: "Sign in",
    rememberMe: "Remenber me",
    forgotPassword: "Forgot Password",
    actionLogin: "Login",
    dontHaveAccount: "Don't have an Account?",
    signUp: "Sign up",
    phoneNumber: "Phone number",
    emailValidationError: "Email validation error;",
    myProfiles: "My profiles",
    menu_user: "User",
    addNew: "Add New",
    updateYourCV: "Update your CV",
    uploadFile: "Upload File",
    buttonDelete: "Delete",
    postTitle: "Post title",
    minSalary: "Minimum salary",
    maxSalary: "Maximum salary",
    userBio: "Bio",
    btnContinue: "Continue",
    profileSkills: "Profile skills",
    btnUpdate: "Update",
    titleEducation: "Education",
    educationFrom: "From",
    educationTo: "To",
    educationDegree: "Degree",
    educationSchool: "School",
    educationGpa: "Grade",
    educationfieldOfStudy: "Field of study",
    educationCollege: "College",
    currentlyWorking: "Currently working here",
    currentlyStudying: "Currently Sudying here",
    currentlyShort: "Current",
    addTimeline: "Add Timeline",
    educationAdded: "Education successfully added on your profile",
    menuHome: "Home",
    myResume: "My Resume",
    editTimeline: "Edit Timeline",
    educationEdited: "Education successfully edited",
    deletionAreYouSure: 'Are you sure?',
    deletionIrreversibleAlert: "You won't be able to revert this!",
    deletionActionButton: "Yes, delete it!",
    deletionDeleted: "Deleted!",
    deletionSuccessMessage: 'Your deletion operation succeed.',
    deletionActionCancel: "Cancel",
    deletionProcess: "Deletion in progress",
    requestGlobalErrorMessage: "Something went wrong",
    experienceCompany: "Company",
    textDescrption: "Description",
    workExperience: "Work & Experience",
    experienceAdded: "Experience successfully added on your profile",
    experienceEdited: "Experience successfully edited",
    experienceSector: "Activity sector",
    markCvDefault: "Mark cv as default",
    deleteButtonText: "Delete",
    introEditedSuccessfuly: "Profile successfuly edited",
    textDefault: "Default",
    jobsOfTheDay: "Jobs of the day",
    searchAndConnect: "Search and get the job if your dreams.",
    textSearch: "Search",
    postAJob: "Post a Job",
    textAdmin: "Admin",
    postNewJob: "Post New Job",
    jobInformations: "Job informations",
    jobTitle: "Job title",
    jobType: "Type",
    workplaceType: "Workplace type",
    workingTypePart: "Part time",
    workingTypeFull: "Full",
    workingTypeRemote: "Remote",
    workingTypeFreelancer: "Freelancer",
    jobExperienceYear: "Nombre d'années d'expérience",
    jobExperienceLevel: "Experience Level",
    jobExpEntry: "Entry",
    jobExpInterm: "Intermediate",
    jobExpSenior: "Senior",
    jobExpExpert: "Expert",
    jobMinSalary: "Min. salary",
    jobMaxSalary: "Max. salary",
    jobWorkPlaceOnsite: "On site",
    jobWorkPlaceRemote: "Remote",
    jobWorkPlaceHybrid: "Hybrid",
    jobApplicationEnd: "Application end",
    jobVisaSponsored: "Visa Sponsored",
    jobDescription: "Description",
    jobResponsabilities: "Job responsabilities",
    jobSpecialNote: "Special job note for user",
    jobIndustry: "Industry",
    jobCompany: "Company recruiting",
    jobSkills: "Skills",
    textJobs: "Jobs",
    jobCountry: "Country",
    jobCity: "City",
    jobProcess: "Recruitment processes (Separate with commas)",
    jobIncludePertners: "Include partners",
    textJobDetails: "Job Details",
    textJobApplications: "Job Applications",
    textCvTheque: "Cv Theque",
    textProfileDetails: "Profile details",
    textDownloadCv: "Download CV",
    textProfileSteps: "Etape du profil",
    textShortBio: "Short Bio",
    textSkills: "Skills",
    textAboutMe: "About Me",
    textOverview: "Overview",
    textExpectedSalary: "Expected Salary",
    textLinkedIn: "LinkedIn",
    textPhone: "Phone",
    textEmail: "Email",
    buttonSendMessage: "Send Message",
    textMyProfile: "My Profile",
    textUpdateProfile: "Update your profile",
    textUploadAvatar: "Upload Avatar",
    textFirstName: "First Name",
    textLastName: "Last Name",
    textPhoneNumber: "Phone Number",
    textLanguage: "Language",
    buttonSaveChange: "Save Change",
    textContactInformation: "Contact Information",
    textLngFrench: "French",
    textLngEnglish: "English",
    textContactInformation: "Contact Information",
    textSocialNetwork: "Social Network",
    textTwitter: "Twitter",
    textWebsite: "Website",
    textLinkedin: "Linkedin",
    textTwitter: "Twitter",
    textPerMonth: "Month",
    textEmploymentInfo: "Employment Information",
    textIndustry: "Industry",
    textJobLevel: "Job level",
    textSalary: "Salary",
    textExperience: "Experience",
    textYears: "Years",
    textJobType: "Job type",
    textCreated: "Created",
    textDeadline: "Deadline",
    textLocation: "Location",
    textApplyNow: "Apply now",
    textShareJob: "Share this",
    textEmail: "Email",
    textSimilarJobs: "Similar jobs",
    footerDescription: "Ovasourcing emploi is the leader in the sector of personnel placement and HR consulting in Cameroon and Central Africa",
    footerTitleContactInfo: "Contact Info",
    footerAddress: "Address",
    footerTitleLinks: "LIEN DU SITE",
    textAllJobs: "All jobs",
    textContact: "Contact",
    footerTitleNewsletter: "Newsletter",
    footerTextNewslettet: "Subscribe to our newsletter to receive all our offers continuously.",
    textJObsAvailable: "Jobs Available",
    textSearch: "Search",
    textFindTheJOb: "",
    textJobBoard: `
    The #1 <span className="text-primary">Job Board for</span>
                <br class="d-none d-lg-block" />
                Find the job that suits you`,
    textYourKeyword: "Your keyword... ",
    textInscriptionCreateAccount: "Sign up & create profiles",
    textInscriptionCreateAccountText: "Is this your first visit to our site? If so, we invite you to create an account and complete your profile to complete the application process.",
    textSearchJob: "Search job",
    textSearchJobText: "Enter a job title or keyword, Enter a location Click on the find a job button.",
    textHowToApply: "How to apply ?",
    textHowToApplyText: "Once you have found an offer you like, you have two choices: apply using your profile, or with your CV",
    textSeeSomeWords: "See Some Words",
    textThousand: "Thousands of employee get their ideal jobs",
    andFeedBack: "and feed back to us!",
    jobApplicationSuccess: "Application success",
    textNewProfile: "New profile",
    myApplications: "My applications",
    textAppliedOn: "Applied on",
    textLoginingOut: "Login out ...",
    textDashboard: "Dashboard",
    textApplications: "Applications",
    textMyProfile: "My Profile",
    cvManage: "CV Manage",
    textLogout: "Logout",
    textAdvanceFilter: "Advance Filter",
    textReset: "Reset",
    textDescription: "Description",
    tetSkills: "Skills",
    textResponsabilities: "Responsabilities",
    textProfileEditedSuccess: "Profile sucessfuly edited",
    textApplicationStatus: "Application status",
    text_sent: "Sent",
    text_in_progress: "In progress",
    text_interview: "Interview",
    text_test: "Test",
    text_hr: "RH",
    text_human_ressources: "Hman ressources",
    text_rejected: "Rejected",
    text_validated: "Validated",
    text_waiting_user: "Waiting your confirmation",
    text_accepted: "Accepted",
    text_canceled: "Canceled",
    textHome: "Home",
    textAvailableNow: "Available Now",
    textGithub: "Github",
    textMadeBy: "Made by",
    textUser: "User",
    textFindJob: "Find a Job",
    textYourAccount: "Your Account",
    textLogin: "Login",
    textRegister: "Register",
    textSignIn: "Login",
    textNoJob: "No job found",
    textLoginError: "Unable to log in with provided credentials.",
    textWelcomeBack: "Welcome back!",
    textAtMost: "The email should have at most 50 characters",
    textValidEmail: "Email address must be a valid address",
    textEmailNotFound: "Email not found",
    textResetPw: "Reset password",
    textRecoverPwEmailSend: "Password reset e-mail has been sent.",
    textConfirmError: "Password reset confirmation failed",
    textPwMustMatch: "Your passwords do no match",
    textSetNewPassword: "Set New Password",
    textNoNotifs: "No notifications found",
    textNotifications: "Notifications",
    textTitle: "Title",
    textContent: "Content",
    textNotificationsDetails: "Notification Details",
    textNotifDetailsError: "Sorry, we cannot display this notification, please try later",
    textProfileEditedError: "Sorry, we can't edit the profile, and error occured",
    textEmailVerified: "Email successfuly verified",
    textResendVerificationAction: "Resend",
    textResendVerification: "Resend Verification code",
    textCheckEmail: "Check email and try again",
    textKeyResend: "Verification code has been resend. Check your mail box!",
    emailRevalidationError: "We cannot send the revalidation code, please check your email address and try again.",
    textEmailVerificationKeyNotFound: "Email verification key not found. Try login or resend the verification link.",
    textNberPlaces: "Opened places",
    textNberAppl: "Applications",
    textGender: "Gender",
    textAge: "Age",
    textChoose: "Choose an option",
    textMale: "Male",
    textFemale: "Female",
    textMale: "Male",
    textNewsWill: `New Things Will Always<br/> Update Regularly`,
    textSubscribe: "Subscribe",
    textEnterEmail: "Enter your email here",
    textThankForSubscribing: "Thank you for subscribing to our newsletter.",
    textNewsletterFailed: "Your subscription failed.",
    textOurProcess: "Our process",
    textAppliedJobs: "Applications",
    textJobsCount: "Jobs count",
    textJobFound: "Job found",
    textProfiles: "Profiles",
    textRecommendedJobs: "Recommended jobs",
    textTopRecruitment: "Top recruitment",
    textApplicationDetails: "Application details",
    textHistory: "History",
    textApplicationInformations: "Application informations",
    textAppliedOn: "Applied on",
    textIsActive: "Is active",
    textYes: "Yes",
    textNo: "No",
    textStep: "Step",
    textJob: "Job",
    textPreviousStep: "Previous step",
    textApplicationNotFound: "Applicationn not found",
    textActions: "Actions",
    textCancelApplication: "Cancel my application",
    textAcceptJob: "Accept job",
    textYesCancel: "Yes, cancel",
    textApplicationCancelError: "An error occured while cancelling your application",
    textApplicationCancelled: "Job application canncelled !",
    textApplicationAccepted: "Application accepted",
    textApplicationAcceptationError: "Application acceptation failed",
    textIntroduction: "Introduction",
    textCertifications: "Certifications",
    textLanguages: "Languages",
    textReferences: "References",
    textAddEducation: "Add education",
    textEditEducation: "Edit education",
    textPrevious: "Previous",
    textNext: "Next",
    textAddExperience: "Add experience",
    textEditExperience: "Edit experoence",
    textCertifName: "Certification name",
    textDate: "Date",
    textCertifAdded: "Certification successfully added",
    textCertifAddingError: "Certification creation error!",
    textAddCertification: "Add certification",
    textEditCertification: "Edit certification",
    textJoinedFile: "Joined files",
    textLangName: "Language name",
    textLevel: "Level",
    textAddLanguage: "Add language",
    textEditLanguage: "Edit language",
    textLanguageAddingError: "Language adding error !",
    languageEdited: "Language successfully edited",
    textLanguageAdded: "Language successfully added",
    textName: "Name",
    textAddReference: "Add reference",
    textEditReference: "Ediit reference",
    textReferenceAddingError: "Reference adding error !",
    referenceEdited: "Reference successfully edited",
    textReferenceAdded: "Reference successfully added",
    textViewResumes: "View My resumes",
    textApplication: "Job Application",
    textStartUrCareer: "Start your career today",
    textChooseProfile: "Please choose your profile and send it to the employer.",
    textFalse: "False",
    textTrue: "True",
    textAdditionalInformations: "Additional informations",
    textFormFieldRequired: "This field is required",
    textDateObtained: "Date Obtained",
    textSubmit: "Submit",
    textBeginner: "Beginner",
    textIntermediate: "Intermediate",
    textAdvanced: "Advanced",
    textPopCat: "Popular category",
    textPopCatText: "Search and connect with the right candidates faster.",
    textByLocation: "Jobs by Location",
    textByLocationText: "Find your favourite jobs and get the benefits of yourself"
};
