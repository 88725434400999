import { URLS } from "../../services/urls";
import useSWR from "swr";
import ProfileCard from "./components/ProfileCard";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

const JobApplicationModal = (props) => {
  const { onClose, job } = props;
  const [selected, setSelected] = useState(1);
  const [step, setStep] = useState(1);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({});

  const { data, isLoading } = useSWR(URLS.USERS.PROFILE.my);

  const handleClose = () => {
    if (job?.questions.length === 0) {
      if (selected && onClose) {
        onClose(selected);
        window?.$("#ModalApplyJobForm").modal("hide");
      }
    } else {
      window?.$("#submitExtradata").trigger("click");
    }
  };

  const onSubmit = (data) => {
    console.log(selected && onClose)
    if (selected && onClose) {
      onClose(selected, data);
      window?.$("#ModalApplyJobForm").modal("hide");
    }
  };

  useEffect(() => {
    if(job?.questions?.length > 0){
      setStep(1);
    } else {
      setStep(2);
    }
  }, [job])

  return (
    <div
      className="modal fade"
      id="ModalApplyJobForm"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content apply-job-form">
          <button
            className="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="modal-body pl-30 pr-30 pt-50">
            <div className="text-center">
              <p className="font-sm text-brand-2"> {t("textApplication")} </p>
              <h2 className="mt-10 mb-5 text-brand-1 text-capitalize">
                {t("textStartUrCareer")}
              </h2>
              <p className="font-sm text-muted mb-30">
                {t("textChooseProfile")} {" "} {job?.questions?.length > 0 ? `(${step}/2)` : ''}
              </p>
            </div>
            {step === 2 && <div className="row">
              {data?.results?.map((profile) => (
                <div key={"profile" + profile.id} className="col-md-6 col-12">
                  <div onClick={() => setSelected(profile.id)}>
                    <ProfileCard
                      profile={profile}
                      showUtils
                      active={selected === profile.id}
                    />
                  </div>
                </div>
              ))}
              <div className="col-md-6 col-12">
                <Link to={`/dashboard/my-resumes/manage/create`}>
                  <ProfileCard
                    profile={{ primary_role: "+ " + t("textNewProfile") }}
                    showUtils
                    active={selected === 3}
                  />
                </Link>
              </div>
            </div>}
            {job?.questions?.length > 0 && (
              <form hidden={step === 2} className=" mt-20" onSubmit={handleSubmit(onSubmit)}>
                <hr />
                <h4 className="text-left w-100 mb-3">
                  {t("textAdditionalInformations")}
                </h4>
                <div className="row">
                  {job?.questions?.map((question, index) => {
                    return question.response_type === "yes/no" ? (
                      <div key={question.id} className="col-md-6 col-12">
                        <div className="form-">
                          <label className="form-label text-left w-100">
                            {question.question}{" "}
                            {question.required ? (
                              <span className="text-primary">*</span>
                            ) : (
                              ""
                            )}
                          </label>
                          {/* [ yes/no, true/false, number, characters ] */}
                          <div class="form-check w-fit">
                            <input
                              className="form-check-input"
                              id={`input-${index}`}
                              type="radio"
                              value={"yes"}
                              {...register(question.id, {
                                required: question.required ? true : false,
                              })}
                            />
                            <label
                              class="form-check-label w-fit"
                              htmlFor={`input-${index}`}
                            >
                              {t("textYes")}
                            </label>
                          </div>
                          <div class="form-check w-fit">
                            <input
                              className="form-check-input"
                              id={`input-${index + "option_2"}`}
                              type="radio"
                              value={"no"}
                              {...register(question.id, {
                                required: question.required ? true : false,
                              })}
                            />
                            <label
                              class="form-check-label w-fit"
                              htmlFor={`input-${index + "option_2"}`}
                            >
                              {t("textNo")}
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : question.response_type === "true/false" ? (
                      <div key={question.id} className="col-md-6 col-12">
                        <div className="form-">
                          <label className="form-label text-left w-100">
                            {question.question}
                          </label>
                          {/* [ yes/no, true/false, number, characters ] */}
                          <div class="form-check w-fit">
                            <input
                              className="form-check-input"
                              id={`input-${index}`}
                              type="radio"
                              value={"true"}
                              {...register(question.id, {
                                required: question.required ? true : false,
                              })}
                            />
                            <label
                              class="form-check-label w-fit"
                              htmlFor={`input-${index}`}
                            >
                              {t("textTrue")}
                            </label>
                          </div>
                          <div class="form-check w-fit">
                            <input
                              className="form-check-input"
                              id={`input-${index + "option_2"}`}
                              type="radio"
                              value={"false"}
                              {...register(question.id, {
                                required: question.required ? true : false,
                              })}
                            />
                            <label
                              class="form-check-label w-fit"
                              htmlFor={`input-${index + "option_2"}`}
                            >
                              {t("textFalse")}
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : question.response_type === "number" ? (
                      <div key={question.id} className="col-md-6 col-12">
                        <div className="form-group">
                          <label className="form-label text-left w-100">
                            {question.question}
                          </label>
                          <input
                            className="form-control"
                            id={`input-${index}`}
                            type="number"
                            {...register(question.id, {
                              required: question.required ? true : false,
                            })}
                            placeholder={question.question}
                          />
                        </div>
                      </div>
                    ) : question.response_type === "characters" ? (
                      <div key={question.id} className="col-md-6 col-12">
                        <div className="form-group">
                          <label className="form-label text-left w-100">
                            {question.question}
                          </label>
                          <input
                            className="form-control"
                            id={`input-${index}`}
                            type="text"
                            {...register(question.id, {
                              required: question.required ? true : false,
                            })}
                            placeholder={question.question}
                          />
                        </div>
                      </div>
                    ) : (
                      <div />
                    );
                  })}
                </div>
                <button
                  type="submit"
                  className="hidden"
                  id="submitExtradata"
                ></button>
              </form>
            )}
            <div className="d-flex mt-20">
            {job?.questions?.length > 0 && step === 2 &&<button
              className="btn btn-secondary mr-auto"
              onClick={() => {
                if(isValid){
                  setStep(1)
                }
              }}
              type="button"
            >
              {" "}
              {t("textPrevious")}
            </button>}
            {step === 2 ?<button
              className="btn btn-default ml-auto"
              onClick={handleClose}
              type="button"
            >
              {" "}
              {t("textSubmit")}
            </button>
            :
            <button
              className="btn btn-default ml-auto"
              onClick={() => {
                if(isValid){
                  setStep(2)
                }
              }}
              type="button"
            >
              {" "}
              {t("textNext")}
            </button>
            }
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default JobApplicationModal;
