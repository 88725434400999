import React from 'react'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const AboutUs = () => {
    const { t } = useTranslation();
    return (

        <>

      <section className="section-box-2 pt-100">
        <div className="container">
          <div className="banner-hero banner-image-single"><img src="/assets/imgs/page/job-single/ban5.jpg" alt="Ovasourcing" /></div>
          <div className="border-bottom pt-10 pb-10"></div>
        </div>
      </section>

      <section className="section-box mt-50">
        <div className="container">
          
        </div>
        </section>
        </>
    );
  };
  

export default AboutUs