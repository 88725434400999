import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";
import { setUserDetails } from "../../components/utils/functions";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/slices/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./passwordInput.css";
import { signInWithGooglePopup } from "../../services/firebase";

const Login = () => {
  const [loading, setLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logGoogleUser = async () => {
    const response = await signInWithGooglePopup();
    const tokenId = response?.user?.accessToken
    if(tokenId){
      setLoading(true);
      API.post(URLS.AUTHS.google, {id_token: tokenId}, {headers:{"Client-Id": URLS.CLIENT_ID}}).then((resp) => {
          const redirect = window.localStorage.getItem("redirect");
          setLoading(false);
          let usrData = resp.data.data;
          setUserDetails(usrData);
          dispatch(loginSuccess(usrData));
          navigate(searchParams.get("redirect") ?? redirect ?? "/");
      }).catch((error) => {
        toast.error(error?.response?.data?.detail ?? t("textLoginError"));

      })
    }
    console.log(response.user);
  }


  const onSubmit = (data) => {
    setLoading(true);
    API.post(URLS.AUTHS.LOGIN, data)
      .then((resp) => {
        const redirect = window.localStorage.getItem("redirect");
        setLoading(false);
        let usrData = resp.data;
        usrData.user.id = usrData.user.pk;
        setUserDetails(usrData);
        dispatch(loginSuccess(usrData));
        navigate(searchParams.get("redirect") ?? redirect ?? "/");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.detail ?? t("textLoginError"));
      });
  };
  return (
    <div className="box-content">
      <div className="box-heading"></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-30">
                <div className="box-padding">
                  <div className="login-register">
                    <div className="row login-register-cover border-none pb-3">
                      <div className="col-lg-5 col-md-6 col-sm-12 mx-auto">
                        <div className="form-login-cover">
                          <div className="text-center">
                            <div>
                            <img
                    alt="Ovasourcing"
                    src="/logo.png"
                    className="app-logo"
                    height={'85px'}
                  />
                            </div>
                            <h2 className="mt-10 mb-5 text-brand-1">
                              Member Login
                            </h2>
                            <p className="font-sm text-muted mb-30">
                              Access to all features. No credit card required.
                            </p>
                            <button onClick={logGoogleUser} className="btn social-login hover-up mb-20">
                              <img
                                src="assets/imgs/template/icons/icon-google.svg"
                                alt="jobbox"
                              />
                              <strong>Sign in with Google</strong>
                            </button>
                            <div className="divider-text-center">
                              <span>Or continue with</span>
                            </div>
                          </div>
                          <form
                            className="login-register text-start mt-20"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-1">
                                {t("email")} *
                              </label>
                              <input
                                className="form-control"
                                id="input-1"
                                type="email"
                                required=""
                                {...register("email", {
                                  required: t("textFormFieldRequired"),
                                  validate: {
                                    maxLength: (v) =>
                                      v.length <= 50 || t("textAtMost"),
                                    matchPattern: (v) =>
                                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                        v
                                      ) || t("textValidEmail"),
                                  },
                                })}
                                placeholder="stevenjob@gmail.com"
                              />
                              <span className="text-primary">
                                {errors.email?.message && (
                                  <small>{errors.email.message}</small>
                                )}
                              </span>
                            </div>
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-4">
                                {t("password")} *
                              </label>
                              <input
                                className="form-control"
                                id="input-4"
                                type={showPassword ? "text" : "password"}
                                {...register("password")}
                                required=""
                                placeholder="************"
                              />
                              <div
                                className="eye-icon"
                                onClick={togglePasswordVisibility}
                              >
                                <FontAwesomeIcon
                                  icon={showPassword ? faEye : faEyeSlash}
                                />
                              </div>
                            </div>
                            <div className="login_footer form-group d-flex justify-content-between">
                              <label className="cb-container">
                                <input type="checkbox" />
                                <span className="text-small">
                                  {t("remember Me")}
                                </span>
                                <span className="checkmark"></span>
                              </label>
                              <Link className="text-muted" to="/password-reset">
                                {t("forgot Password")}
                              </Link>
                            </div>
                            <div className="form-group">
                              <button
                                className="btn btn-brand-1 hover-up w-100"
                                type="submit"
                                name="login"
                                disabled={loading}
                              >
                                {t("actionLogin")}
                                {loading && (
                                  <div
                                    className="spinner-border ml-10 spinner-border-sm"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                )}
                              </button>
                            </div>
                            <div className="text-muted text-center">
                              {t("dontHaveAccount")}{" "}
                              <Link to="/register">{t("signUp")}</Link>
                            </div>
                          </form>
                        </div>
                        <div className="img-2">
                          <img
                            src="/assets/imgs/page/login-register/img-3.svg"
                            alt="Ovasourcing"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-10"> 
          <div className="section-box">
            <div className="container"> 
              <div className="panel-white pt-30 pb-30 pl-15 pr-15">
                <Clients />
              </div>
            </div>
          </div>
        </div> */}
    </div>
  );
};

export default Login;
