import { Link, useParams } from "react-router-dom";
import NewsLetter from "../../components/newsletter";
import JobApplicationModal from "../../components/jobs/JobApplicationModal";
import useSWR from "swr";
import { URLS } from "../../services/urls";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ApplyButton from "../../components/actions/ApplyButton";
import { API } from "../../services/axios";
import CountUp from 'react-countup';
import { DEFAULT_IMAGE } from "../../components/utils/consts";


const JobDetails = () => {

  const { jobId } = useParams();
  const [similar, setSimilar] = useState();

  const {data, isLoading} = useSWR(URLS.JOBS.details(jobId));

  const {t} = useTranslation();

  useEffect(() => {
    if(data) {
      API.get(URLS.JOBS.byIndustry(data?.industry?.name)).then((resp) => {
        setSimilar(resp?.data?.results)
      })
    }

  }, [data]);

    return (
       <>
       {/* <JobApplicationModal /> */}
        <section className="section-box-2 pt-100">
        <div className="container">
          <div className="banner-hero banner-image-single"><img src="/assets/imgs/page/job-single/thumb.png" alt="Ovasourcing" /></div>
          <div className="row mt-10">
            <div className="col-lg-8 col-md-12">
              <h3>{data?.title}</h3>
              <div className="mt-0 mb-15"><span className="card-briefcase">{data?.work_place}</span><span className="card-time">{moment(data?.created_at).fromNow()}</span></div>
            </div>
            <div className="col-lg-4 col-md-12 text-lg-end">
                    <ApplyButton jobId={jobId} job={data} />
            </div>
          </div>
          <div className="border-bottom pt-10 pb-10"></div>
        </div>
      </section>
      <section className="section-box mt-50">
        <div className="container">
        <div className="d-flex mb-20">
              {
                isLoading && <div
                className="spinner-border m-auto spinner-border-md"
                role="status"
              >
                <span className="visually-hidden">
                  Loading...
                </span>
              </div>
              }
            </div>
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="job-overview bg-white">
                <h5 className="border-bottom pb-15 mb-30">{t("textEmploymentInfo")}</h5>
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="sidebar-icon-item"><img src="/assets/imgs/page/job-single/industry.svg" alt="Ovasourcing" /></div>
                    <div className="sidebar-text-info ml-10"><span className="text-description industry-icon mb-10">{t("textIndustry")}</span><strong className="small-heading">{data?.industry?.name}</strong></div>
                  </div>
                  <div className="col-md-6 d-flex mt-sm-15">
                    <div className="sidebar-icon-item"><img src="/assets/imgs/page/job-single/job-level.svg" alt="Ovasourcing" /></div>
                    <div className="sidebar-text-info ml-10"><span className="text-description joblevel-icon mb-10">{t("textJobLevel")}</span><strong className="small-heading">{data?.experience_level}</strong></div>
                  </div>
                </div>
                <div className="row mt-25">
                  <div className="col-md-6 d-flex mt-sm-15">
                    <div className="sidebar-icon-item"><img src="/assets/imgs/page/job-single/salary.svg" alt="Ovasourcing" /></div>
                    <div className="sidebar-text-info ml-10"><span className="text-description salary-icon mb-10">{t("textSalary")}</span><strong className="small-heading">XAF {data?.salary_min}</strong></div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="sidebar-icon-item"><img src="/assets/imgs/page/job-single/experience.svg" alt="Ovasourcing" /></div>
                    <div className="sidebar-text-info ml-10"><span className="text-description experience-icon mb-10">{t('textExperience')}</span><strong className="small-heading">{data?.experience} {t("textYears")}</strong></div>
                  </div>
                </div>
                <div className="row mt-25">
                  <div className="col-md-6 d-flex mt-sm-15">
                    <div className="sidebar-icon-item"><img src="/assets/imgs/page/job-single/job-type.svg" alt="Ovasourcing" /></div>
                    <div className="sidebar-text-info ml-10"><span className="text-description jobtype-icon mb-10">{t("textJobType")}</span><strong className="small-heading">{data?.type}</strong></div>
                  </div>
                  <div className="col-md-6 d-flex mt-sm-15">
                    <div className="sidebar-icon-item"><img src="/assets/imgs/page/job-single/deadline.svg" alt="Ovasourcing" /></div>
                    <div className="sidebar-text-info ml-10"><span className="text-description mb-10">{t("textCreated")}</span><strong className="small-heading">{moment(data?.created_at).format()}</strong></div>
                  </div>
                </div>
                <div className="row mt-25">
                  <div className="col-md-6 d-flex mt-sm-15">
                    <div className="sidebar-icon-item"><img src="/assets/imgs/page/job-single/updated.svg" alt="Ovasourcing" /></div>
                    <div className="sidebar-text-info ml-10"><span className="text-description jobtype-icon mb-10">{t("textDeadline")}</span><strong className="small-heading">{moment(data?.application_end).fromNow()}</strong></div>
                    {/* <div className="sidebar-text-info ml-10"><span className="text-description jobtype-icon mb-10">{t("textDeadline")}</span><strong className="small-heading">{moment(data?.application_end).format('MMMM Do YYYY, h:mm:ss a')}</strong></div> */}
                  </div>
                  <div className="col-md-6 d-flex mt-sm-15">
                    <div className="sidebar-icon-item"><img src="/assets/imgs/page/job-single/location.svg" alt="Ovasourcing" /></div>
                    <div className="sidebar-text-info ml-10"><span className="text-description mb-10">{t("textLocation")}</span><strong className="small-heading">{data?.city[0]?.name}, {data?.country[0]?.name}</strong></div>
                  </div>
                </div>
                <div className="row mt-25">
                  <div className="col-md-6 d-flex mt-sm-15">
                    <div className="sidebar-icon-item"><img src="/assets/imgs/page/job-single/job-level.svg" alt="Ovasourcing" /></div>
                    <div className="sidebar-text-info ml-10"><span className="text-description mb-10">{t("textNberPlaces")}</span><strong className="small-heading">
                    <CountUp delay={3} end={data?.required} />
                    </strong></div>
                  </div>
                  <div className="col-md-6 d-flex mt-sm-15">
                    <div className="sidebar-icon-item"><img src="/assets/imgs/page/job-single/job-level.svg" alt="Ovasourcing" /></div>
                    <div className="sidebar-text-info ml-10"><span className="text-description mb-10">{t("textNberAppl")}</span><strong className="small-heading">
                      <CountUp delay={3} end={data?.submitted} />
                      </strong></div>
                  </div>

                </div>
              </div>
              <div className="job-overview bg-white">
                <div className="content-single">
                  <h4>{t("textDescription")}</h4>
                  <p dangerouslySetInnerHTML={{__html: data?.description}}></p>
                  <h4 className="mt-30">{t("tetSkills")}</h4>
                  <div>
                  {
                  data?.skills?.map((skill) => (
                    <Link key={skill?.id} className="btn btn-tags-sm mr-5 mb-2" to="#">
                  {skill?.name}
                </Link>
                  ))
                }
                  </div>
                  <h4>{t("textResponsabilities")}</h4>
                  <div dangerouslySetInnerHTML={{__html: data?.responsabilities}}>
                    
                  </div>
                </div>
                <div className="author-single"><span>Ovasourcing</span></div>
                <div className="single-apply-jobs">
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <ApplyButton jobId={jobId} job={data} />
                      {/* <Link className="btn btn-border" href="#">Save job</Link> */}
                      </div>
                    <div className="col-md-7 text-lg-end social-share">
                      <h6 className="color-text-paragraph-2 d-inline-block d-baseline mr-10">{t("textShareJob")}</h6>
                      <Link className="mr-5 d-inline-block d-middle" to={`https://www.facebook.com/sharer.php?u=${window.location.href}`}><img alt="Ovasourcing" src="/assets/imgs/template/icons/share-fb.svg" /></Link>
                      <Link className="mr-5 d-inline-block d-middle" to={`https://twitter.com/intent/tweet?url=${window.location.href}`}><img alt="Ovasourcing" src="/assets/imgs/template/icons/share-tw.svg"/></Link>
                      <Link className="d-inline-block d-middle" to={`whatsapp://send?text=${window.location.href}`}><img alt="Ovasourcing" src="/assets/imgs/template/icons/share-whatsapp.svg"/></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
              {/* <div className="sidebar-border">
                <div className="sidebar-heading">
                  <div className="avatar-sidebar">
                    <figure><img alt="Ovasourcing" src="/assets/imgs/page/job-single/avatar.png" /></figure>
                    <div className="sidebar-info"><span className="sidebar-company">{data?.client?.name}</span><span className="card-location">{data?.city[0]?.name}, {data?.country[0]?.name}</span><Link className="link-underline mt-15" href="#">02 Open Jobs</Link></div>
                  </div>
                </div>
                <div className="sidebar-list-job">
                  <div className="box-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.3150609575905!2d-87.6235655!3d41.886080899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2ca8b34afe61%3A0x6caeb5f721ca846!2s205%20N%20Michigan%20Ave%20Suit%20810%2C%20Chicago%2C%20IL%2060601%2C%20Hoa%20K%E1%BB%B3!5e0!3m2!1svi!2s!4v1658551322537!5m2!1svi!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                  <ul className="ul-disc">
                    {/* <li>205 North Michigan Avenue, Suite 810 Chicago, 60601, USA</li>
                    <li>Phone: (123) 456-7890</li> *
                    <li>{t("textEmail")}: {data?.client?.email}</li>
                  </ul>
                </div>
              </div> */}
              <div className="sidebar-border">
                <h6 className="f-18">{t('textSimilarJobs')}</h6>
                <div className="sidebar-list-job">
                  <ul>
                    {
                      similar && similar?.map((sim) => {
                        if(sim.id != jobId) {
                          return (

                            <li key={"sim" + sim.id}>
                              <Link  to={`/job/${sim.id}/details/`} className="card-list-4 wow animate__animated animate__fadeIn hover-up">
                                <div className="image" style={{backgroundImage: `url(${sim.image ?? DEFAULT_IMAGE})`}}></div>
                                <div className="info-text1">
                                  <h5 className="font-md font-bold color-brand-1"><span>{sim?.title}</span></h5>
                                  <div className="mt-0"><span className="card-briefcase">{sim?.type}</span><span className="card-time">{moment(sim?.created_at).fromNow()}</span></div>
                                  <div className="mt-5">
                                    <div className="row">
                                      <div className="col-12">
                                        {/* <h6 className="card-price">$250<span>/Hour</span></h6> */}
                                      </div>
                                      <div className="col-12"><span className="location-small">{sim?.city[0]?.name}, {sim?.country[0]?.name}</span></div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          )
                        }
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section-box mt-50 mb-50">
        <div className="container">
          <div className="text-left">
            <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">Featured Jobs</h2>
            <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">Get the latest news, updates and tips</p>
          </div>
          <div className="mt-50">
            <div className="box-swiper style-nav-top">
              <div className="swiper-container swiper-group-4 swiper">
                <div className="swiper-wrapper pb-10 pt-5">
                  <div className="swiper-slide">
                    <div className="card-grid-2 hover-up">
                      <div className="card-grid-2-image-left"><span className="flash"></span>
                        <div className="image-box"><img src="/assets/imgs/brands/brand-6.png" alt="Ovasourcing" /></div>
                        <div className="right-info"><Link className="name-job" href="company-details.html">Quora JSC</Link><span className="location-small">New York, US</span></div>
                      </div>
                      <div className="card-block-info">
                        <h6><Link href="job-details.html">Senior System Engineer</Link></h6>
                        <div className="mt-5"><span className="card-briefcase">Part time</span><span className="card-time">5<span> minutes ago</span></span></div>
                        <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                        <div className="mt-30"><Link className="btn btn-grey-small mr-5" href="job-details.html">PHP</Link><Link className="btn btn-grey-small mr-5" href="job-details.html">Android    </Link>
                        </div>
                        <div className="card-2-bottom mt-30">
                          <div className="row">
                            <div className="col-lg-7 col-7"><span className="card-text-price">$800</span><span className="text-muted">/Hour</span></div>
                            <div className="col-lg-5 col-5 text-end">
                              <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="card-grid-2 hover-up">
                      <div className="card-grid-2-image-left"><span className="flash"></span>
                        <div className="image-box"><img src="/assets/imgs/brands/brand-4.png" alt="Ovasourcing" /></div>
                        <div className="right-info"><Link className="name-job" href="company-details.html">Dailymotion</Link><span className="location-small">New York, US</span></div>
                      </div>
                      <div className="card-block-info">
                        <h6><Link href="job-details.html">Frontend Developer</Link></h6>
                        <div className="mt-5"><span className="card-briefcase">Full time</span><span className="card-time">6<span> minutes ago</span></span></div>
                        <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                        <div className="mt-30"><Link className="btn btn-grey-small mr-5" href="jobs-grid.html">Typescript</Link><Link className="btn btn-grey-small mr-5" href="jobs-grid.html">Java</Link>
                        </div>
                        <div className="card-2-bottom mt-30">
                          <div className="row">
                            <div className="col-lg-7 col-7"><span className="card-text-price">$250</span><span className="text-muted">/Hour</span></div>
                            <div className="col-lg-5 col-5 text-end">
                              <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="card-grid-2 hover-up">
                      <div className="card-grid-2-image-left"><span className="flash"></span>
                        <div className="image-box"><img src="/assets/imgs/brands/brand-8.png" alt="Ovasourcing" /></div>
                        <div className="right-info"><Link className="name-job" href="company-details.html">Periscope</Link><span className="location-small">New York, US</span></div>
                      </div>
                      <div className="card-block-info">
                        <h6><Link href="job-details.html">Lead Quality Control QA</Link></h6>
                        <div className="mt-5"><span className="card-briefcase">Full time</span><span className="card-time">6<span> minutes ago</span></span></div>
                        <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                        <div className="mt-30"><Link className="btn btn-grey-small mr-5" href="job-details.html">iOS</Link><Link className="btn btn-grey-small mr-5" href="job-details.html">Laravel</Link><Link className="btn btn-grey-small mr-5" href="job-details.html">Golang</Link>
                        </div>
                        <div className="card-2-bottom mt-30">
                          <div className="row">
                            <div className="col-lg-7 col-7"><span className="card-text-price">$250</span><span className="text-muted">/Hour</span></div>
                            <div className="col-lg-5 col-5 text-end">
                              <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="card-grid-2 hover-up">
                      <div className="card-grid-2-image-left"><span className="flash"></span>
                        <div className="image-box"><img src="/assets/imgs/brands/brand-4.png" alt="Ovasourcing" /></div>
                        <div className="right-info"><Link className="name-job" href="company-details.html">Dailymotion</Link><span className="location-small">New York, US</span></div>
                      </div>
                      <div className="card-block-info">
                        <h6><Link href="job-details.html">Frontend Developer</Link></h6>
                        <div className="mt-5"><span className="card-briefcase">Full time</span><span className="card-time">6<span> minutes ago</span></span></div>
                        <p className="font-sm color-text-paragraph mt-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto eveniet, dolor quo repellendus pariatur.</p>
                        <div className="mt-30"><Link className="btn btn-grey-small mr-5" href="jobs-grid.html">Typescript</Link><Link className="btn btn-grey-small mr-5" href="jobs-grid.html">Java</Link>
                        </div>
                        <div className="card-2-bottom mt-30">
                          <div className="row">
                            <div className="col-lg-7 col-7"><span className="card-text-price">$250</span><span className="text-muted">/Hour</span></div>
                            <div className="col-lg-5 col-5 text-end">
                              <div className="btn btn-apply-now" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Apply now</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-button-next swiper-button-next-4"></div>
              <div className="swiper-button-prev swiper-button-prev-4"></div>
            </div>
            <div className="text-center"><Link className="btn btn-grey" href="#">Load more posts</Link></div>
          </div>
        </div>
      </section> */}
      <NewsLetter />
      </>
    )
}

export default JobDetails;