import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDWSSwqzuntEQcY1tCyZGT-n0vbOB-AurM",
    authDomain: "ovasourcing.firebaseapp.com",
    projectId: "ovasourcing",
    storageBucket: "ovasourcing.appspot.com",
    messagingSenderId: "584916874557",
    appId: "1:584916874557:web:f63ade440d478ad560c154"
  };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account "
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);