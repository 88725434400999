import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ApplyButton from "../../../components/actions/ApplyButton";
import { formatLinkString } from "../../../components/utils/functions";

const JobCardList = (props) => {
    const { job } = props;
    const {t} = useTranslation()
  return (
    <div className="col-xl-12 col-12">
      <div className="card-grid-2 hover-up">
        <span className="flash"></span>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card-grid-2-image-left">
              <div className="image-box image-box-2" style={{backgroundImage: `url(${job?.image ? formatLinkString(job?.image) : "/assets/imgs/brands/brand-1.png"})`}}>
                {/* <img src={job?.image ? formatLinkString(job?.image) : "/assets/imgs/brands/brand-1.png"} alt={job.title} /> */}
              </div>
              <div className="right-info">
                <Link to={`/job/${job.id}/details/`} className="name-job">{job.title}</Link>
                <span className="location-small">{job.country[0]?.name}, {job.city[0]?.name}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 text-start text-md-end pr-60 col-md-6 col-sm-12">
            <div className="pl-15 mb-15 mt-30">
            {
                job?.skills?.slice(0,2)?.map((skill) => (
                  <Link key={skill?.id} className="btn btn-tags-sm mr-5 text-paragraph-1 mb-5" to="#">
                {skill?.name}
              </Link>
                )) 
              }
            </div>
          </div>
        </div>
        <div className="card-block-info">
          <h4>
            {/* <a href="job-details.html">UI / UX Designer fulltime</a> */}
          </h4>
          <div className="mt-5">
            <span className="card-briefcase">{job.type}</span>
            <span className="card-time">
            {moment(job?.created_at).fromNow()}
            </span>
            <span className="card-time bg-none p-0">
        {t("textNberPlaces")}: {job.required}, {" "}
        {t("textNberAppl")}: {job.submitted},  {" "}
        {t("textDeadline")}: {moment(job?.application_end).fromNow()}
        </span>
          </div>
          <p className="font-sm color-text-paragraph text-paragraph-2 mt-10" dangerouslySetInnerHTML={{__html: job.description}}>
            
          </p>
          <div className="card-2-bottom mt-20">
            <div className="row">
              {/* <div className="col-lg-7 col-7">
                <span className="card-text-price">XAF {job.salary_min} - XAF {job.salary_max}</span>
                <span className="text-muted ml-10">/{t('textPerMonth')}</span>
              </div> */}
              <div className="col-lg-5 col-5 text-end">
                <ApplyButton jobId={job.id}  job={job} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCardList;
