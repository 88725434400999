import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Clients from "../../../home/components/Clients";

const AuthFooter = () => {
  const { t } = useTranslation();

  return (
    <>
    <Clients/>
    <footer className="footer mt-20">
      <div className="container">
        <div className="box-footer">
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-25 text-center text-md-start">
              <p className="font-sm color-text-paragraph-2">
                © {new Date().getFullYear()} -{" "}
                <Link className="color-brand-2" to="#" target="_blank">
                  Ovasourcing{" "}
                </Link>
                {t("textDashboard")} <span> {t("textMadeBy")} </span>
                <Link className="color-brand-2" to="#" target="_blank">
                  {" "}
                  OVA Consulting
                </Link>
              </p>
            </div>
            {/* <div className="col-md-6 col-sm-12 text-center text-md-end mb-25">
              <ul className="menu-footer">
                <li>
                  <Link to="#">About</Link>
                </li>
                <li>
                  <Link to="#">Careers</Link>
                </li>
                <li>
                  <Link to="#">Policy</Link>
                </li>
                <li>
                  <Link to="#">Contact</Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
    </>
  );
};

export default AuthFooter;
