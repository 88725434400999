import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../services/logout";
import { DEFAULT_PROFILE } from "../utils/consts";
import { formatLinkString } from "../utils/functions";
import useSWR from "swr";
import { URLS } from "../../services/urls";
import NotifDetails from "./components/NotifDetails";
import { useState } from "react";
import { API } from "../../services/axios";
import { toast } from "react-toastify";

const AuthHeader = () => {
  const {
    auth,
  } = useSelector((state) => state.auth);

  const handleChange = (event) => {
    event.preventDefault();
  }

  const { t } = useTranslation();

  const { data: notifications, isLoading } = useSWR(URLS.NOTIFICATIONS.messages("", 1));

  const [notif, setNotif] = useState();

  const handleDisplayNotif = (id) => {
    API.get(URLS.NOTIFICATIONS.details(id)).then((resp) => {
      const { data } = resp;
      setNotif(data)
      window.$("#NotificationDetails").modal("show");
    }).catch((error) => {
      toast.error(error?.response?.data?.detail ?? t("textNotifDetailsError"))
    })
  }

  // useEffect(() => {
  //   function mobileHeaderActive() {
  //     var navbarTrigger = window.$(".burger-icon"),
  //         endTrigger = window.$(".mobile-menu-close"),
  //         container = window.$(".mobile-header-active"),
  //         wrapper4 = window.$("body");
  //     wrapper4.prepend('<div class="body-overlay-1"></div>');
  //     navbarTrigger.on("click", function(e) {
  //         navbarTrigger.toggleClass("burger-close");
  //         e.preventDefault();
  //         container.toggleClass("sidebar-visible");
  //         wrapper4.toggleClass("mobile-menu-active");
  //         console.log("jj")
  //     });
  //     endTrigger.on("click", function() {
  //         container.removeClass("sidebar-visible");
  //         wrapper4.removeClass("mobile-menu-active");
  //     });
  //     window.$(".body-overlay-1").on("click", function() {
  //         container.removeClass("sidebar-visible");
  //         wrapper4.removeClass("mobile-menu-active");
  //         navbarTrigger.removeClass("burger-close");
  //     });
      
  // }
  // mobileHeaderActive();
  // }, []);
  return (
    <>
      <header className="header sticky-bar">
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <Link className="d-flex mr-10" to="/">
                  <img
                    alt="Ovasourcing"
                    src="/logo.png"
                    className="app-logo"
                    height={'85px'}
                  />
                </Link>
              </div>
              {/* <span className="btn btn-grey-small ml-10">User area</span> */}
            </div>
            <div className="header-search">
              <div className="box-search">
                <form action="/search">
                  <input
                    className="form-control input-search"
                    type="text"
                    name="search"
                    placeholder="Search"
                    onChange={handleChange}
                  />
                </form>
              </div>
            </div>
            <div className="header-menu d-none d-md-block">
              <ul>
                <ul>
                  <li>
                    {" "}
                    <Link to="/">{t("textHome")} </Link>
                  </li>
                  {/* <li>
                    {" "}
                    <Link to="/">{t("textAboutUs")} </Link>
                  </li> */}
                  <li>
                    {" "}
                    <Link to="/search">{t("Jobs")}</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/about">{t("About Us")} </Link>
                  </li> 
                </ul>
              </ul>
            </div>
            <div className="header-right">
              <div className="block-signin">
                {/* <Link
                  className="btn btn-default icon-edit hover-up"
                  to="post-job.html"
                >
                  Post Job
                </Link> */}
                <div className="dropdown d-inline-block">
                  <Link
                    className="btn btn-notify"
                    id="dropdownNotify"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-display="static"
                  ></Link>
                  <ul
                    className="dropdown-menu dropdown-menu-light dropdown-menu-end"
                    aria-labelledby="dropdownNotify"
                  >
                    {
                      notifications?.results.length === 0 && <li><span className="dropdown-item text-center"><small>{t("textNoNotifs")}</small></span></li>
                    }

                    {
                      notifications?.results?.map((notif) => {
                        return  (
                          <li key={notif.id}>
                            <Link className={`dropdown-item ${notif.opened ? '' : "active"}`} to="#" onClick={() => handleDisplayNotif(notif.id)}>
                              <small>
                              <strong>{notif.title}</strong>
                              </small>
                              <br />
                              <small>{notif.content}</small>
                            </Link>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                <div className="member-login">
                  <img alt="" src={auth?.user?.picture ? formatLinkString(auth?.user?.picture) : DEFAULT_PROFILE} />
                  <div className="info-member">
                    {" "}
                    <strong className="color-brand-1">
                      {auth?.user?.first_name} {auth?.user?.last_name}
                    </strong>
                    <div className="dropdown">
                      <Link
                        className="font-xs color-text-paragraph-2 icon-down"
                        id="dropdownProfile"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-display="static"
                      >
                        {auth?.user?.user_level}
                      </Link>
                      <ul
                        className="dropdown-menu dropdown-menu-light dropdown-menu-end"
                        aria-labelledby="dropdownProfile"
                      >
                        <li>
                          <Link className="dropdown-item" to="">
                          {t('textDashboard')}
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/my-applications">
                          {t('textApplications')}
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/profile">
                          {t('textMyProfile')}
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/my-resumes">
                          {t("cvManage")}
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#" onClick={() => logout(t)}>
                          {t("textLogout")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="burger-icon burger-icon-white">
        <span className="burger-icon-top"></span>
        <span className="burger-icon-mid"></span>
        <span className="burger-icon-bottom"></span>
      </div>
      <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="perfect-scroll">
              <div className="mobile-search mobile-header-border mb-30">
                <form action="#">
                  <input type="text" placeholder="Search…" />
                  <i className="fi-rr-search"></i>
                </form>
              </div>
              <div className="mobile-menu-wrap mobile-header-border">
                {/* <!-- mobile menu start--> */}
                <nav>
                  <ul className="main-menu">
                    <li>
                      {" "}
                      <Link className="dashboard2 active " to="">
                        <img
                          src="/assets/imgs/page/dashboard/dashboard.svg"
                          alt="Ovasourcing"
                        />
                        <span className="name">{t('textDashboard')}</span>
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link
                        className="dashboard2 text-left"
                        to="/my-applications"
                      >
                        <img
                          src="/assets/imgs/page/dashboard/tasks.svg"
                          alt="Ovasourcing"
                        />
                        <span className="name">{t('textApplications')}</span>
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link
                        className="dashboard2 text-left"
                        to="/profile"
                      >
                        <img
                          src="/assets/imgs/page/dashboard/profiles.svg"
                          alt="Ovasourcing"
                        />
                        <span className="name">{t('textMyProfile')}</span>
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link
                        className="dashboard2 text-left"
                        to="/my-resumes"
                      >
                        <img
                          src="/assets/imgs/page/dashboard/cv-manage.svg"
                          alt="Ovasourcing"
                        />
                        <span className="name">{t("cvManage")}</span>
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link className="dashboard2 text-left" to="/login">
                        <img
                          src="/assets/imgs/page/dashboard/logout.svg"
                          alt="Ovasourcing"
                        />
                        <span className="name">{t("textLogout")}</span>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              
              <div className="site-copyright">
                Copyright {new Date().getFullYear()} &copy; Ovasourcing. <br />
              </div>
            </div>
          </div>
        </div>
      <NotifDetails notif={notif} />
      </div>
    </>
  );
};
export default AuthHeader;
