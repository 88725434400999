import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './InputWithInfo.css';

const InputWithInfo = ({ placeholder, infoText }) => {
    const [showInfo, setShowInfo] = useState(false);

    const toggleInfoVisibility = () => {
      setShowInfo(!showInfo);
    };
  return (
    <div className="input-with-info">
    <input type="text" placeholder={placeholder} className="input-field" />
    <div className="info-icon" onClick={toggleInfoVisibility}>
      <FontAwesomeIcon icon={faInfoCircle} />
    </div>
    {showInfo && <div className="info-text">{infoText}</div>}
  </div>
  )
}

export default InputWithInfo