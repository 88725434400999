import moment from "moment";
import { useTranslation } from "react-i18next";



const NotifDetails = ({notif}) => {

    const { t } = useTranslation()

    return (

        <div className="modal fade" id="NotificationDetails" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content apply-job-form">
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body pl-30 pr-30 pt-50">
              <div className="text-center">
                <h2 className="mt-10 mb-5 text-brand-1 text-capitalize">{t("textNotificationsDetails")}</h2>
              </div>
              <div>
                  <table className="w-100">
                      <tbody>
                          <tr>
                              <td>{t("textTitle")}</td>
                              <td>{notif?.title}</td>
                          </tr>
                          <tr>
                              <td>{t("textContent")}</td>
                              <td>{notif?.content}</td>
                          </tr>
                          <tr>
                              <td>{t("textCreated")}</td>
                              <td>{moment(notif?.title).format("lll")}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default NotifDetails;