import { useEffect, useState } from "react";
import NewsLetter from "../../components/newsletter";
import JobCard from "../../components/jobs/JobCard";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { URLS } from "../../services/urls";
import useSWR from "swr";
import JobCardList from "./components/JobCardList";
import SearchFilter from "./components/SearchFilter";
import { DEFAULT_PAGE_COUNT } from "../../components/utils/consts";
import _ from "lodash";

const JobSearch = () => {
  const [view, setView] = useState("LIST");
  let [searchParams, setSearchParams] = useSearchParams();
  const [industry, setIndustry] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const [showIndustries, setShowIndustries] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");

  const handleChangePage = (event, pg) => {
    event.preventDefault();
    setPage(pg);
  };

  const { data: locations, isLoading: loadingLocations } = useSWR(URLS.CONFIGS.PROFILE.COUNTRIES.CITIES.cmr(""));


  const { data: categories, isLoading: loadingCategories } = useSWR(
    URLS.CONFIGS.PROFILE.SECTOR.list("")
  );
  const { data: jobs, isLoading: loadingJobs } = useSWR(
    URLS.JOBS.search(
      page,
      search,
      industry?.name,
      location?.name,
    )
  );

  const handleChangeView = (event, view) => {
    event.preventDefault();
    setView(view);
  };

  const { t } = useTranslation();

  useEffect(() => {
    console.log("searchParams.get('search')", searchParams.get("search"));

    setIndustry({id: searchParams.get("industry")});
    setSearch(searchParams.get("search"));
    setLocation({name: searchParams.get("location")})
  }, [searchParams.get("industry"), searchParams.get("location"), searchParams.get("search"), loadingCategories]);

  return (
    <>
      <section className="section-box-2 pt-100">
        <div className="container">
          <div className="banner-hero banner-single banner-single-bg">
            <div className="block-banner text-center">
              <h3 className="wow animate__animated animate__fadeInUp">
                <span className="color-brand-2">{jobs?.count} {t("textJobs")}</span>{" "}
                {t("textAvailableNow")}
              </h3>
              <div
                className="font-sm color-text-paragraph-2 mt-10 wow animate__animated animate__fadeInUp"
                data-wow-delay=".1s"
              >
                {t('textFindTheJOb')}
              </div>
              <div
                className="form-find text-start mt-40 wow animate__animated animate__fadeInUp"
                data-wow-delay=".2s"
              > 
                {/* <form>
                  <div className="box-industry">
                    <select
                      className="form-input mr-10 select-active input-industry"
                      value={industry}
                      onChange={(e) => setIndustry(e.target.value)}
                    >
                      {categories?.results?.map((cat) => (
                        <option key={cat?.id} value={cat?.id}>
                          {cat?.name}
                        </option>
                      ))}
                    </select>
                    
                  </div>
                  <input
                    className="form-input input-keysearch mr-10"
                    // defaultValue={searchParams.get("search")}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    placeholder="Your keyword... "
                  />
                  <button className="btn btn-default btn-find font-sm">
                    {t("textSearch")}
                  </button>
                </form> */}
                <form
                >
                  <div className="job_filters_wrap d-md-flex w-100">
                    <div className="box-industry">
                      {
                        showIndustries && <div class="dropdown" id="dropdown">
                          {categories?.results?.map((cat) => (
                        <a href="#" className={category?.id === cat.id ? 'active' : ''}  key={cat?.id} onClick={() => {
                          setCategory(cat);
                          setShowIndustries(false);
                        }}>
                        <i class="bx bx-plus-circle"></i>
                        {cat?.name}
                      </a>
                      ))}
                      </div>
                      }
                      <label
                      htmlFor="search_category"
                        className="select2 select2-container select2-container--default elect2-container--open"
                        dir="ltr"
                        style={{ width: "158.359375px" }}
                        onClick={() => setShowIndustries(!showIndustries)}
                      >
                        <span className="selection">
                          <span
                            className="select2-selection select2-selection--single"
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabindex="0"
                            aria-labelledby="select2-search_category-56-container"
                          >
                            <span
                              className="select2-selection__rendered"
                              id="select2-search_category-56-container"
                              title="Industry"
                            >
                              {industry?.name ?? categories?.results?.filter((item) => item.id === industry.id)[0]?.name ?? t("textIndustry")}
                            </span>
                            <span
                              className="select2-selection__arrow"
                              role="presentation"
                            >
                              <b role="presentation"></b>
                            </span>
                          </span>
                        </span>
                        <span
                          className="dropdown-wrapper"
                          aria-hidden="true"
                        ></span>
                      </label>
                    </div>
                    <div className="box-industry">
                    {
                        showLocation && <div class="dropdown" id="dropdown">
                          {locations?.results?.map((cat) => (
                        <a href="#" className={location?.id === cat.id ? 'active' : ''}  key={cat?.id} onClick={() => {
                          setLocation(cat);
                          setShowLocation(false);
                        }}>
                        <i class="bx bx-plus-circle"></i>
                        {cat?.name}
                      </a>
                      ))}
                      </div>
                      }
                    <label
                      className="select2 select2-container select2-container--default"
                      dir="ltr"
                      style={{width: "91.75px"}}
                      onClick={() => setShowLocation(!showLocation)}
                    >
                      <span className="selection">
                        <span
                          className="select2-selection select2-selection--single"
                          role="combobox"
                          aria-haspopup="true"
                          aria-expanded="false"
                          tabindex="0"
                          aria-labelledby="select2-search_location-container"
                        >
                          <span
                            className="select2-selection__rendered"
                            id="select2-search_location-container"
                            title="Location"
                          >
                            { location?.name ?? t("textLocation")}
                          </span>
                          <span
                            className="select2-selection__arrow"
                            role="presentation"
                          >
                            <b role="presentation"></b>
                          </span>
                        </span>
                      </span>
                      <span
                        className="dropdown-wrapper"
                        aria-hidden="true"
                      ></span>
                    </label>
                    </div>

                    <input
                    className="form-input input-keysearch mr-10"
                    // defaultValue={searchParams.get("search")}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    placeholder="Your keyword... "
                  />

<button className="btn btn-default btn-find font-sm">
                    {t("textSearch")}
                  </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-box mt-30">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-9 col-md-12 col-sm-12 col-12 float-right">
              <div className="content-page">
                <div className="box-filters-job">
                  <div className="row">
                    <div className="col-xl-6 col-lg-5">
                      <span className="text-small text-showing">
                        {/* Showing <strong>41-60 </strong>of <strong>944 </strong>
                        jobs */}
                      </span>
                    </div>
                    <div className="col-xl-6 col-lg-7 text-lg-end mt-sm-15">
                      <div className="display-flex2">
                        {/* <div className="box-border mr-10">
                          <span className="text-sortby">Show:</span>
                          <div className="dropdown dropdown-sort">
                            <button
                              className="btn dropdown-toggle"
                              id="dropdownSort"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              data-bs-display="static"
                            >
                              <span>12</span>
                              <i className="fi-rr-angle-small-down"></i>
                            </button>
                            <ul
                              className="dropdown-menu dropdown-menu-light"
                              aria-labelledby="dropdownSort"
                            >
                              <li>
                                <a className="dropdown-item active" href="#">
                                  10
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  12
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  20
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="box-border">
                          <span className="text-sortby">Sort by:</span>
                          <div className="dropdown dropdown-sort">
                            <button
                              className="btn dropdown-toggle"
                              id="dropdownSort2"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              data-bs-display="static"
                            >
                              <span>Newest Post</span>
                              <i className="fi-rr-angle-small-down"></i>
                            </button>
                            <ul
                              className="dropdown-menu dropdown-menu-light"
                              aria-labelledby="dropdownSort2"
                            >
                              <li>
                                <a className="dropdown-item active" href="#">
                                  Newest Post
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Oldest Post
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Rating Post
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                        <div className="box-view-type">
                          <a
                            className="view-type"
                            href="#"
                            onClick={(event) => handleChangeView(event, "LIST")}
                          >
                            <img
                              src={`/assets/imgs/template/icons/icon-list${view === "LIST" ? "" : ""
                                }.svg`}
                              alt="Ovasourcing"
                            />
                          </a>
                          <a
                            className="view-type"
                            href="#"
                            onClick={(event) => handleChangeView(event, "GRID")}
                          >
                            <img
                              src={`/assets/imgs/template/icons/icon-grid${view === "GRID" ? "-hover" : ""
                                }.svg`}
                              alt="Ovasourcing"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {loadingJobs && (
                  <div className="d-flex">
                    <div
                      className="spinner-border m-auto spinner-border-md"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
                {view === "LIST" && (
                  <div className="row display-list">
                    {jobs?.results?.map((job) => (
                      <JobCardList key={"joblist" + job.id} job={job} />
                    ))}
                  </div>
                )}
                {view === "GRID" && (
                  <div className="row">
                    {jobs?.results?.map((job) => (
                      <div
                        key={"jobcard" + job.id}
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"
                      >
                        <JobCard job={job} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div>
                {
                  !loadingJobs && jobs?.count === 0 && <p className="text-center">{t("textNoJob")}</p>
                }
              </div>
              {jobs?.count > 0 && (
                <div className="paginations">
                  <ul className="pager">
                    <li>
                      <Link
                        tabIndex={-1}
                        onClick={(event) => handleChangePage(event, page - 1)}
                        className={`pager-prev ${page === 1 ? "disabled" : ""}`}
                        to="#"
                      ></Link>
                    </li>
                    {new Array(Math.ceil(jobs.count / DEFAULT_PAGE_COUNT))
                      .fill(3)
                      .map((pag, index) => (
                        <li
                          key={"page" + index}
                          className={` ${page === index + 1 ? "active" : ""}`}
                          aria-current="page"
                        >
                          <Link
                            className={`pager-number ${page * DEFAULT_PAGE_COUNT ===
                                Math.ceil(jobs.count)
                                ? "disabled"
                                : ""
                              } ${page === index + 1 ? "active" : ""}`}
                            to="#"
                            tabIndex={-1}
                            onClick={(event) =>
                              handleChangePage(event, index + 1)
                            }
                          >
                            {index + 1}
                          </Link>
                        </li>
                      ))}
                    <li>
                      <Link
                        tabIndex={-1}
                        className={`pager-next ${page === 1 ? "disabled" : ""}`}
                        to="#"
                      ></Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <SearchFilter locations={locations?.results ?? []} searchResults={jobs?.results ?? []} count={jobs?.count ?? 0} />
            </div>
          </div>
        </div>
      </section>
      <NewsLetter />
    </>
  );
};

export default JobSearch;
