import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./components/styles/styles.css"
import { DEFAULT_IMAGE, DEFAULT_PROFILE } from "../../../../components/utils/consts";
import { CiMail, CiPhone, CiMap, CiSquareChevRight, CiMenuKebab } from "react-icons/ci";
import { VscFilePdf } from "react-icons/vsc";
import { useSelector } from "react-redux";

const MyProfile = () => {
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth)

  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">{t("textMyProfile")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <Link className="icon-home" href="/dashboard">
                  {t("textUser")}
                </Link>
              </li>
              <li>
                <span>{t("textMyProfile")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
                <div>
                    <div className="mb-40">
                        <div className="d-flex mb-10">
                            <h3 className="my-auto">{auth?.user?.first_name + " " + auth?.user?.last_name}</h3>
                            <div className="ml-auto avatar-70">
                                <img src={DEFAULT_PROFILE} alt="" />
                            </div>
                        </div>
                        <div className="bg-white p-20 user-contacts">
                        <div className="d-flex">
                            <div className="mr-auto">
                                <p>
                                    <CiMail /> {" "} {auth?.user?.email}
                                </p>
                                <p>
                                    <CiPhone /> {" "} {auth?.user?.phone_number ?? <span className="underline">Ajouter un numero de telephone</span>}
                                </p>
                                <p>
                                    <CiMap /> {" "} {auth?.user?.address ?? <span className="underline">Ajouter un lieu</span>}
                                </p>
                            </div>
                            <div className="ml-auto my-auto">
                                <CiSquareChevRight fontSize={30} />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="mb-40">
                        <div className="d-flex mb-10">
                            <h4 className="my-auto">CV</h4>
                        </div>
                        <div className="bg-white p-20 user-contacts">
                        <div className="d-flex">
                            <div className="mr-10">
                            <VscFilePdf fontSize={50} />
                            </div>
                            <div className="mr-auto">
                                <p>
                                    <span className="h5">cvfile.pdf</span>
                                </p>
                                <p>Ajouté le 15 mai 2021</p>
                            </div>
                            <div className="ml-auto my-auto">
                                <CiMenuKebab fontSize={20} />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="mb-10">
                        <div className="d-flex mb-10">
                            <h4 className="my-auto">Améliorez la pertinence de vos offres</h4>
                        </div>
                        <div className="bg-white p-20 user-infos">
                        <div className="d-flex pt-15 pb-15">
                            <div className="mr-auto">
                                <p className="h6 ">
                                    <strong>Qualifications</strong>
                                </p>
                                <p>Mettez vos compétences et votre expérience en avant.</p>
                            </div>
                            <div className="ml-auto my-auto">
                                <CiSquareChevRight fontSize={30} />
                            </div>
                        </div>
                        <div className="d-flex pt-15 pb-15 border-top">
                            <div className="mr-auto">
                                <p className="h6 ">
                                    <strong>Préférences d'emploi</strong>
                                </p>
                                <p>Précisez certaines informations, telles que le salaire minimum et l'horaire désirés.</p>
                            </div>
                            <div className="ml-auto my-auto">
                                <CiSquareChevRight fontSize={30} />
                            </div>
                        </div>
                        <div className="d-flex pt-15 pb-15 border-top">
                            <div className="mr-auto">
                                <p className="h6 ">
                                    <strong>Masquer les offres avec ces détails</strong>
                                </p>
                                <p>Gérez les qualifications et préférences à prendre en compte pour masquer certaines offres de votre recherche.</p>
                            </div>
                            <div className="ml-auto my-auto">
                                <CiSquareChevRight fontSize={30} />
                            </div>
                        </div>
                        <div className="d-flex pt-15 pb-15 border-top">
                            <div className="mr-auto">
                                <p className="h6 ">
                                    <strong>Disponible maintenant</strong>
                                </p>
                                <p>Indiquez aux employeurs que vous pouvez commencer dès que possible.</p>
                            </div>
                            <div className="ml-auto my-auto">
                                <CiSquareChevRight fontSize={30} />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
