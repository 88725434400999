import { useTranslation } from "react-i18next";
import AuthFooter from "../../components/footer";
import ApplicationCard from "./components/ApplicationCard";
import useSWR from "swr";
import { URLS } from "../../../../services/urls";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import Swal from "sweetalert2";
import { API } from "../../../../services/axios";
import { toast } from "react-toastify";

const MyApplicationDetails = (props) => {
  const [cancelling, setCancelling] = useState(false);
  const [accepting, setAccepting] = useState(false);

  const { applicationId } = useParams();
  const { t } = useTranslation();
  const { data, isLoading } = useSWR(
    URLS.JOBS.APPLICATIONS.details(applicationId)
  );

  const handleAccept = () => {
    setAccepting(true);
    API.post(URLS.JOBS.APPLICATIONS.accept(applicationId)).then((resp) => {
        setAccepting(false);
        toast.success(t("textApplicationAccepted"))
    }).catch((error) => {
        toast.error(error?.response?.data?.detail ?? t("textApplicationAcceptationError"));
        setAccepting(false);
    })
  };
  const handleCancel = () => {
      Swal.fire({
          title: t("deletionAreYouSure"),
          text: t("deletionIrreversibleAlert"),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: t("textYesCancel")
        }).then((result) => {
            if (result.isConfirmed) {
            setCancelling(true);
            API.post(URLS.JOBS.APPLICATIONS.cancel(applicationId)).then((resp) => {
                setCancelling(false);
                toast.success(t("textApplicationCancelled"))
            }).catch((error) => {
                toast.error(error?.response?.data?.detail ?? t("textApplicationCancelError"));
                setCancelling(false);
            })
        //   Swal.fire(
        //     'Deleted!',
        //     'Your file has been deleted.',
        //     'success'
        //   )
        }
      })
  };

  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35">{t("textApplicationDetails")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <a className="icon-home" href="#">
                  {t("menu_user")}
                </a>
              </li>
              <li>
                <span>{t("textApplicationDetails")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {!isLoading && (
        <section className="section-box mt-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="job-overview bg-white">
                  <h5 className="border-bottom pb-15 mb-30">
                    {t("textApplicationInformations")}
                  </h5>
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="sidebar-icon-item">
                        <img
                          src="/assets/imgs/page/job-single/industry.svg"
                          alt="Ovasourcing"
                        />
                      </div>
                      <div className="sidebar-text-info ml-10">
                        <span className="text-description industry-icon mb-10">
                          {t("textJob")}
                        </span>
                        <Link to={`/job/${data?.offer?.id}/details/`}>
                          <strong className="small-heading">
                            {data?.offer?.title}
                          </strong>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex mt-sm-15">
                      <div className="sidebar-icon-item">
                        <img
                          src="/assets/imgs/page/job-single/job-level.svg"
                          alt="Ovasourcing"
                        />
                      </div>
                      <div className="sidebar-text-info ml-10">
                        <span className="text-description joblevel-icon mb-10">
                          {t("textIsActive")}
                        </span>
                        <strong className="small-heading">
                          {data?.active ? t("textYes") : t("textNo")}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-25">
                    <div className="col-md-6 d-flex">
                      <div className="sidebar-icon-item">
                        <img
                          src="/assets/imgs/page/job-single/experience.svg"
                          alt="Ovasourcing"
                        />
                      </div>
                      <div className="sidebar-text-info ml-10">
                        <span className="text-description experience-icon mb-10">
                          {t("textStep")}
                        </span>
                        <strong className="small-heading">
                          {t(`text_${data?.status}`)}
                        </strong>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex mt-sm-15">
                      <div className="sidebar-icon-item">
                        <img
                          src="/assets/imgs/page/job-single/deadline.svg"
                          alt="Ovasourcing"
                        />
                      </div>
                      <div className="sidebar-text-info ml-10">
                        <span className="text-description mb-10">
                          {t("textAppliedOn")}
                        </span>
                        <strong className="small-heading">
                          {moment(data?.created_at).fromNow()}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-25">
                    <div className="col-md-6 d-flex mt-sm-15">
                      <div className="sidebar-icon-item">
                        <img
                          src="/assets/imgs/page/job-single/job-level.svg"
                          alt="Ovasourcing"
                        />
                      </div>
                      <div className="sidebar-text-info ml-10">
                        <span className="text-description mb-10">
                          {t("textNberPlaces")}
                        </span>
                        <strong className="small-heading">
                          {data?.offer?.required}
                        </strong>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex mt-sm-15">
                      <div className="sidebar-icon-item">
                        <img
                          src="/assets/imgs/page/job-single/job-level.svg"
                          alt="Ovasourcing"
                        />
                      </div>
                      <div className="sidebar-text-info ml-10">
                        <span className="text-description mb-10">
                          {t("textNberAppl")}
                        </span>
                        <strong className="small-heading">
                          {data?.offer?.submitted}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
                {/* <div className="sidebar-border">
                <div className="sidebar-heading">
                  <div className="avatar-sidebar">
                    <figure><img alt="Ovasourcing" src="/assets/imgs/page/job-single/avatar.png" /></figure>
                    <div className="sidebar-info"><span className="sidebar-company">{data?.client?.name}</span><span className="card-location">{data?.city[0]?.name}, {data?.country[0]?.name}</span><Link className="link-underline mt-15" href="#">02 Open Jobs</Link></div>
                  </div>
                </div>
                <div className="sidebar-list-job">
                  <div className="box-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.3150609575905!2d-87.6235655!3d41.886080899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2ca8b34afe61%3A0x6caeb5f721ca846!2s205%20N%20Michigan%20Ave%20Suit%20810%2C%20Chicago%2C%20IL%2060601%2C%20Hoa%20K%E1%BB%B3!5e0!3m2!1svi!2s!4v1658551322537!5m2!1svi!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                  <ul className="ul-disc">
                    {/* <li>205 North Michigan Avenue, Suite 810 Chicago, 60601, USA</li>
                    <li>Phone: (123) 456-7890</li> *
                    <li>{t("textEmail")}: {data?.client?.email}</li>
                  </ul>
                </div>
              </div> */}
                <div className="sidebar-border mb-3">
                  <h6 className="f-18">{t("textActions")}</h6>
                  <div className="sidebar-list-job text-center">
                    {data?.status === "waiting_user" && <button
                      className="btn btn-default mb-3 mx-auto d-flex"
                      onClick={handleAccept}
                    >
                      {t("textAcceptJob")}{" "}
                      {accepting && (
                          <div
                            className="spinner-border ml-10 my-auto spinner-border-sm "
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                      )}
                    </button>}
                    <button
                      className="btn mb-3 mx-auto text-primary d-flex"
                      onClick={handleCancel}
                    >
                      {t("textCancelApplication")}
                      {cancelling && (
                          <div
                            className="spinner-border ml-10 my-auto spinner-border-sm "
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
                <div className="sidebar-border">
                  <h6 className="f-18">{t("textHistory")}</h6>
                  <div className="sidebar-list-job">
                    <ul>
                      {data?.history &&
                        data?.history?.map((sim) => {
                          if (sim.id != 2) {
                            return (
                              <li key={"sim" + sim.id}>
                                <Link
                                  to={`#`}
                                  className="card-list-4 wow animate__animated animate__fadeIn hover-up"
                                >
                                  <div className="info-text">
                                    <h5 className="font-md font-bold color-brand-1">
                                      <span>
                                        {t("textStep")}: {sim?.status}
                                      </span>
                                    </h5>
                                    <div className="mt-0">
                                      {sim.previous && (
                                        <span className="card-briefcase">
                                          {t("textPreviousStep")}:{" "}
                                          {sim?.previous}
                                        </span>
                                      )}{" "}
                                      <br />{" "}
                                      <span className="card-time">
                                        {moment(sim?.created_at).fromNow()}
                                      </span>
                                    </div>
                                    <div className="mt-5">
                                      <div className="row">
                                        <div className="col-12">
                                          {/* <h6 className="card-price">$250<span>/Hour</span></h6> */}
                                        </div>
                                        <div className="col-12">
                                          <span className="location-small"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            );
                          }
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section>
        {isLoading && (
          <div className="d-flex">
            <div
              className="spinner-border m-auto spinner-border-md "
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {!isLoading && !data && <p> {t("textApplicationNotFound")} </p>}
      </section>
      <AuthFooter />
    </div>
  );
};

export default MyApplicationDetails;
