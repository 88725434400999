import { Link, useLocation } from "react-router-dom";
import { logout } from "../../services/logout";
import { useTranslation } from "react-i18next";


const NavBar = (props) => {

    const { group } = props;
    const { pathname } = useLocation();
    const { t } = useTranslation();

    return (
        <div className="nav mt-19 pt-50">
          {/* <Link className="btn btn-expanded"></Link> */}
        <nav className="nav-main-menu">
          <ul className="main-menu">
            <li> <Link className={`dashboard2 ${pathname === "/" ? 'active' : ''}`} to="/"><img src="/assets/imgs/page/dashboard/dashboard.svg" alt="Ovasourcing" /><span className="name">{t("textDashboard")}</span></Link>
            </li>
            <li> <Link className={`dashboard2 ${pathname.includes("/my-applications") ? 'active' : ''}`} to="/my-applications"><img src="/assets/imgs/page/dashboard/tasks.svg" alt="Ovasourcing" /><span className="name">{t("textApplications")}</span></Link>
            </li>
            <li> <Link className={`dashboard2 ${pathname.includes("/profile") ? 'active' : ''}`} to="/profile"><img src="/assets/imgs/page/dashboard/profiles.svg" alt="Ovasourcing" /><span className="name">{t('textMyProfile')}</span></Link>
            </li>
            <li> <Link className={`dashboard2 ${pathname.includes("/my-resumes") ? 'active' : ''}`} to="/my-resumes"><img src="/assets/imgs/page/dashboard/cv-manage.svg" alt="Ovasourcing" /><span className="name">{t("cvManage")}</span></Link>
            </li>
            <li> <Link className={`dashboard2 ${pathname.includes("/logout") ? 'active' : ''}`} to="#" onClick={() => logout(t)}><img src="/assets/imgs/page/dashboard/logout.svg" alt="Ovasourcing" /><span className="name">{t("textLogout")}</span></Link>
            </li>
          </ul>
        </nav>
        <div className="border-bottom mb-20 mt-20"></div>
        <div className="box-profile-completed text-center mb-30">
        <div id="circle-staticstic-demo" class="svg-container"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 194 186" class="circliful">undefined<circle cx="100" cy="100" r="57" class="border" fill="#d8e0fd" stroke="#d8e0fd" stroke-width="10" stroke-dasharray="360" transform="rotate(-90,100,100)"></circle><circle class="circle" cx="100" cy="100" r="57" fill="none" stroke="#3498DB" stroke-width="10" stroke-dasharray="241.20000000000002, 20000" transform="rotate(-90,100,100)"></circle><circle cx="100" cy="100" r="28.5" fill="none"></circle>undefined<text class="timer" text-anchor="middle" x="100" y="100" style={{fontSize: "20px"}} fill="#05264E"><tspan class="number">67</tspan><tspan class="percent">%</tspan></text></svg></div>
          <h6 className="mb-10">Profile Completed</h6>
          <p className="font-xs color-text-mutted">Please add detailed information to your profile. This will help you develop your career more quickly.</p>
        </div>
        <div className="sidebar-border-bg mt-50"><span className="text-grey">WE ARE</span><span className="text-hiring">HIRING</span>
          <p className="font-xxs color-text-paragraph mt-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto</p>
          <div className="mt-15"><Link className="btn btn-paragraph-2" to="#">Know More</Link></div>
        </div>
      </div>
    )
}

export default NavBar;