import { Link } from "react-router-dom";
import useSWR from "swr";
import { URLS } from "../../../services/urls";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DEFAULT_IMAGE } from "../../../components/utils/consts";
import AuthFooter from "../components/footer";
import CountUp from "react-countup";
import Footer from "../../../components/footer";

const UserDashboard = () => {
  const { data, isLoading } = useSWR(URLS.DASHBOARD.list);

  const { t } = useTranslation();

  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35 text-left">{t("textDashboard")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <Link className="icon-home" to="">
                  {t("textUser")}
                </Link>
              </li>
              <li>
                <span>{t("textDashboard")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xxl-8 col-xl-7 col-lg-7">
          <div className="section-box">
          <div className="row"> 
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-4 col-sm-6"> 
                  <div className="card-style-1 hover-up">
                    <div className="card-image"> <img src="assets/imgs/page/dashboard/computer.svg" alt="jobBox"/></div>
                    <div className="card-info"> 
                      <div className="card-title">
                        <h3>1568<span className="font-sm status up">25<span>%</span></span>
                        </h3>
                      </div>
                      <p className="color-text-paragraph-2">Interview Schedules</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                  <div className="card-style-1 hover-up">
                    <div className="card-image"> <img src="assets/imgs/page/dashboard/bank.svg" alt="jobBox"/></div>
                    <div className="card-info"> 
                      <div className="card-title">
                        <h3>284<span className="font-sm status up">5<span>%</span></span>
                        </h3>
                      </div>
                      <p className="color-text-paragraph-2">Applied Jobs</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                  <div className="card-style-1 hover-up">
                    <div className="card-image"> <img src="assets/imgs/page/dashboard/lamp.svg" alt="jobBox"/></div>
                    <div className="card-info"> 
                      <div className="card-title">
                        <h3>136<span className="font-sm status up">12<span>%</span></span>
                        </h3>
                      </div>
                      <p className="color-text-paragraph-2">Task Bids Won</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                  <div className="card-style-1 hover-up">
                    <div className="card-image"> <img src="assets/imgs/page/dashboard/headphone.svg" alt="jobBox"/></div>
                    <div className="card-info"> 
                      <div className="card-title">
                        <h3>985<span className="font-sm status up">5<span>%</span></span>
                        </h3>
                      </div>
                      <p className="color-text-paragraph-2">Application Sent</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                  <div className="card-style-1 hover-up">
                    <div className="card-image"> <img src="assets/imgs/page/dashboard/look.svg" alt="jobBox"/></div>
                    <div className="card-info"> 
                      <div className="card-title">
                        <h3>165<span className="font-sm status up">15<span>%</span></span>
                        </h3>
                      </div>
                      <p className="color-text-paragraph-2">Profile Viewed</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                  <div className="card-style-1 hover-up">
                    <div className="card-image"> <img src="assets/imgs/page/dashboard/open-file.svg" alt="jobBox"/></div>
                    <div className="card-info"> 
                      <div className="card-title">
                        <h3>2356<span className="font-sm status down">- 2%</span>
                        </h3>
                      </div>
                      <p className="color-text-paragraph-2">New Messages</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                  <div className="card-style-1 hover-up">
                    <div className="card-image"> <img src="assets/imgs/page/dashboard/doc.svg" alt="jobBox"/></div>
                    <div className="card-info"> 
                      <div className="card-title">
                        <h3>254<span className="font-sm status up">2<span>%</span></span>
                        </h3>
                      </div>
                      <p className="color-text-paragraph-2">Articles Added</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                  <div className="card-style-1 hover-up">
                    <div className="card-image"> <img src="assets/imgs/page/dashboard/man.svg" alt="jobBox"/></div>
                    <div className="card-info"> 
                      <div className="card-title">
                        <h3>548<span className="font-sm status up">48<span>%</span></span>
                        </h3>
                      </div>
                      <p className="color-text-paragraph-2">CV Added</p>
                    </div>
                  </div>
                </div>
              </div>
          </div>

          <div className="section-box">
            <div className="container">
              <div className="panel-white">
                <div className="panel-head">
                  <h5>{t("textTopRecruitment")}</h5>
                  <Link
                    className="menudrop"
                    id="dropdownMenu3"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-display="static"
                  ></Link>
                  <ul
                    className="dropdown-menu dropdown-menu-light dropdown-menu-end"
                    aria-labelledby="dropdownMenu3"
                  >
                    <li>
                      <Link className="dropdown-item active" to="#">
                        Add new
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="#">
                        Settings
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="#">
                        Actions
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="panel-body">
                  <div className="card-style-2 hover-up">
                    <div className="card-head">
                      <div className="card-image">
                        {" "}
                        <img
                          src="assets/imgs/page/dashboard/img1.png"
                          alt="Ovasourcing"
                        />
                      </div>
                      <div className="card-title">
                        <h6>Senior Full Stack Engineer, Creator Success</h6>
                        <span className="job-type">Full time</span>
                        <span className="time-post">3mins ago</span>
                        <span className="location">New York, US</span>
                      </div>
                    </div>
                    <div className="card-tags">
                      {" "}
                      <Link className="btn btn-tag">Figma</Link>
                      <Link className="btn btn-tag">Adobe XD</Link>
                    </div>
                    <div className="card-price">
                      <strong>$300</strong>
                      <span className="hour">/Hour</span>
                    </div>
                  </div>
                  <div className="card-style-2 hover-up">
                    <div className="card-head">
                      <div className="card-image">
                        {" "}
                        <img
                          src="assets/imgs/page/dashboard/img2.png"
                          alt="Ovasourcing"
                        />
                      </div>
                      <div className="card-title">
                        <h6>Senior Full Stack Engineer, Creator Success</h6>
                        <span className="job-type">Full time</span>
                        <span className="time-post">3mins ago</span>
                        <span className="location">Chicago, US</span>
                      </div>
                    </div>
                    <div className="card-tags">
                      {" "}
                      <Link className="btn btn-tag">Figma</Link>
                      <Link className="btn btn-tag">Adobe XD</Link>
                      <Link className="btn btn-tag">PSD</Link>
                    </div>
                    <div className="card-price">
                      <strong>$650</strong>
                      <span className="hour">/Hour</span>
                    </div>
                  </div>
                  <div className="card-style-2 hover-up">
                    <div className="card-head">
                      <div className="card-image">
                        {" "}
                        <img
                          src="assets/imgs/page/dashboard/img3.png"
                          alt="Ovasourcing"
                        />
                      </div>
                      <div className="card-title">
                        <h6>Lead Product/UX/UI Designer Role</h6>
                        <span className="job-type">Full time</span>
                        <span className="time-post">3mins ago</span>
                        <span className="location">Paris, France</span>
                      </div>
                    </div>
                    <div className="card-tags">
                      {" "}
                      <Link className="btn btn-tag">Figma</Link>
                      <Link className="btn btn-tag">Adobe XD</Link>
                      <Link className="btn btn-tag">PSD</Link>
                    </div>
                    <div className="card-price">
                      <strong>$1200</strong>
                      <span className="hour">/Hour</span>
                    </div>
                  </div>
                  <div className="card-style-2 hover-up">
                    <div className="card-head">
                      <div className="card-image">
                        {" "}
                        <img
                          src="assets/imgs/page/dashboard/img4.png"
                          alt="Ovasourcing"
                        />
                      </div>
                      <div className="card-title">
                        <h6>Marketing Graphic Designer</h6>
                        <span className="job-type">Full time</span>
                        <span className="time-post">3mins ago</span>
                        <span className="location">Tokyto, Japan</span>
                      </div>
                    </div>
                    <div className="card-tags">
                      {" "}
                      <Link className="btn btn-tag">Figma</Link>
                      <Link className="btn btn-tag">Adobe XD</Link>
                      <Link className="btn btn-tag">PSD</Link>
                    </div>
                    <div className="card-price">
                      <strong>$580</strong>
                      <span className="hour">/Hour</span>
                    </div>
                  </div>
                  <div className="card-style-2 hover-up">
                    <div className="card-head">
                      <div className="card-image">
                        {" "}
                        <img
                          src="assets/imgs/page/dashboard/img5.png"
                          alt="Ovasourcing"
                        />
                      </div>
                      <div className="card-title">
                        <h6>Director, Product Design - Creator</h6>
                        <span className="job-type">Full time</span>
                        <span className="time-post">3mins ago</span>
                        <span className="location">Ha Noi, Vietnam</span>
                      </div>
                    </div>
                    <div className="card-tags">
                      {" "}
                      <Link className="btn btn-tag">Figma</Link>
                      <Link className="btn btn-tag">Adobe XD</Link>
                      <Link className="btn btn-tag">PSD</Link>
                    </div>
                    <div className="card-price">
                      <strong>$1500</strong>
                      <span className="hour">/Hour</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-4 col-xl-5 col-lg-5">
          <div className="section-box">
            <div className="container">
              <div className="panel-white">
                <div className="panel-head">
                  <h5>{t("textRecommendedJobs")}</h5>
                  {/* <Link className="menudrop" id="dropdownMenu4" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"></Link> */}
                  {/* <ul className="dropdown-menu dropdown-menu-light dropdown-menu-end" aria-labelledby="dropdownMenu4">
                      <li><Link className="dropdown-item active" to="#">Add new</Link></li>
                      <li><Link className="dropdown-item" to="#">Settings</Link></li>
                      <li><Link className="dropdown-item" to="#">Actions</Link></li>
                    </ul> */}
                </div>
                <div className="panel-body">
                  {isLoading ? (
                    <div
                      className="spinner-border my-auto ml-10 spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <ul>
                      {data?.recommended_jobs?.map((sim) => {
                        return (
                          <li key={"sim" + sim.id} className="mb-3">
                            <Link
                              to={`/job/${sim.id}/details/`}
                              className="card-list-4 wow animate__animated animate__fadeIn hover-up"
                            >
                              <div
                                className="image"
                                style={{
                                  backgroundImage: `url(${
                                    sim.image ?? DEFAULT_IMAGE
                                  })`,
                                }}
                              ></div>
                              <div className="info-text">
                                <h5 className="font-md font-bold color-brand-1">
                                  <span>{sim?.title}</span>
                                </h5>
                                <div className="mt-0">
                                  <span className="card-briefcase">
                                    {sim?.type}
                                  </span>
                                  <span className="card-time">
                                    {moment(sim?.created_at).fromNow()}
                                  </span>
                                </div>
                                <div className="mt-5">
                                  <div className="row">
                                    <div className="col-12">
                                      {/* <h6 className="card-price">$250<span>/Hour</span></h6> */}
                                    </div>
                                    <div className="col-12">
                                      <span className="location-small">
                                        {sim?.city[0]?.name},{" "}
                                        {sim?.country[0]?.name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {/* <div className="card-style-3 hover-up">
                      <div className="card-image online"><img src="assets/imgs/page/dashboard/avata1.png" alt="Ovasourcing" /></div>
                      <div className="card-title"> 
                        <h6>Robert Fox</h6><span className="job-position">UI/UX Designer</span>
                      </div>
                      <div className="card-location  background-image-none p-0"> <span className="location">Chicago, US</span></div>
                      <div className="card-rating"><img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <span className="font-xs color-text-mutted">
                           (65)</span></div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="section-box">
              <div className="container"> 
                <div className="panel-white">
                  <div className="panel-head"> 
                    <h5>Top Recruiters</h5><Link className="menudrop" id="dropdownMenu5" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"></Link>
                    <ul className="dropdown-menu dropdown-menu-light dropdown-menu-end" aria-labelledby="dropdownMenu5">
                      <li><Link className="dropdown-item active" to="#">Add new</Link></li>
                      <li><Link className="dropdown-item" to="#">Settings</Link></li>
                      <li><Link className="dropdown-item" to="#">Actions</Link></li>
                    </ul>
                  </div>
                  <div className="panel-body">
                    <div className="row"> 
                      <div className="col-lg-6 col-md-6 pr-5 pl-5">
                        <div className="card-style-4 hover-up">
                          <div className="d-flex">
                            <div className="card-image"><img src="assets/imgs/page/dashboard/avata1.png" alt="Ovasourcing" /></div>
                            <div className="card-title"> 
                              <h6>Robert Fox</h6><img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star-none.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star-none.svg" alt="Ovasourcing" /> <span className="font-xs color-text-mutted">
                                 (65)</span>
                            </div>
                          </div>
                          <div className="card-location  background-image-none p-0 d-flex"><span className="location">Red, CA</span><span className="jobs-number">25 Open Jobs</span></div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 pr-5 pl-5">
                        <div className="card-style-4 hover-up">
                          <div className="d-flex">
                            <div className="card-image"><img src="assets/imgs/page/dashboard/avata2.png" alt="Ovasourcing" /></div>
                            <div className="card-title"> 
                              <h6>Cody Fisher</h6><img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star-none.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star-none.svg" alt="Ovasourcing" /> <span className="font-xs color-text-mutted">
                                 (65)</span>
                            </div>
                          </div>
                          <div className="card-location  background-image-none p-0 d-flex"><span className="location">Chicago, US</span><span className="jobs-number">25 Open Jobs</span></div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 pr-5 pl-5">
                        <div className="card-style-4 hover-up">
                          <div className="d-flex">
                            <div className="card-image"><img src="assets/imgs/page/dashboard/avata3.png" alt="Ovasourcing" /></div>
                            <div className="card-title"> 
                              <h6>Jane Cooper</h6><img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star-none.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star-none.svg" alt="Ovasourcing" /> <span className="font-xs color-text-mutted">
                                 (65)</span>
                            </div>
                          </div>
                          <div className="card-location  background-image-none p-0 d-flex"><span className="location">Austin, TX</span><span className="jobs-number">25 Open Jobs</span></div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 pr-5 pl-5">
                        <div className="card-style-4 hover-up">
                          <div className="d-flex">
                            <div className="card-image"><img src="assets/imgs/page/dashboard/avata4.png" alt="Ovasourcing" /></div>
                            <div className="card-title"> 
                              <h6>Jerome Bell</h6><img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star-none.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star-none.svg" alt="Ovasourcing" /> <span className="font-xs color-text-mutted">
                                 (65)</span>
                            </div>
                          </div>
                          <div className="card-location  background-image-none p-0 d-flex"><span className="location">Remote</span><span className="jobs-number">25 Open Jobs</span></div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 pr-5 pl-5">
                        <div className="card-style-4 hover-up">
                          <div className="d-flex">
                            <div className="card-image"><img src="assets/imgs/page/dashboard/avata5.png" alt="Ovasourcing" /></div>
                            <div className="card-title"> 
                              <h6>Floyd Miles</h6><img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star-none.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star-none.svg" alt="Ovasourcing" /> <span className="font-xs color-text-mutted">
                                 (65)</span>
                            </div>
                          </div>
                          <div className="card-location  background-image-none p-0 d-flex"><span className="location">Boston, US</span><span className="jobs-number">25 Open Jobs</span></div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 pr-5 pl-5">
                        <div className="card-style-4 hover-up">
                          <div className="d-flex">
                            <div className="card-image"><img src="assets/imgs/page/dashboard/avata1.png" alt="Ovasourcing" /></div>
                            <div className="card-title"> 
                              <h6>Devon Lane</h6><img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star-none.svg" alt="Ovasourcing" /> <img src="assets/imgs/page/dashboard/star-none.svg" alt="Ovasourcing" /> <span className="font-xs color-text-mutted">
                                 (65)</span>
                            </div>
                          </div>
                          <div className="card-location  background-image-none p-0 d-flex"><span className="location">Chicago, US</span><span className="jobs-number">25 Open Jobs</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-box">
              <div className="container"> 
                <div className="panel-white">
                  <div className="panel-head"> 
                    <h5>Queries by search</h5><Link className="menudrop" id="dropdownMenu6" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"></Link>
                    <ul className="dropdown-menu dropdown-menu-light dropdown-menu-end" aria-labelledby="dropdownMenu6">
                      <li><Link className="dropdown-item active" to="#">Add new</Link></li>
                      <li><Link className="dropdown-item" to="#">Settings</Link></li>
                      <li><Link className="dropdown-item" to="#">Actions</Link></li>
                    </ul>
                  </div>
                  <div className="panel-body">
                     <div className="card-style-5 hover-up">
                      <div className="card-title"> 
                        <h6 className="font-sm">Developer</h6>
                      </div>
                      <div className="card-progress"> 
                        <div className="number font-sm">2635</div>
                        <div className="progress">
                          <div className="progress-bar" style={{width: "100%"}}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <AuthFooter />
    </div>
  );
};

export default UserDashboard;
